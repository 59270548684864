.app {
  padding-top: 5.6rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &--no-pd {
    padding-top: 0;
  }

  &__header {
    pointer-events: all;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: auto;
    min-height: 5.6rem;
    position: fixed;
    will-change: transform;
    transition: transform 350ms var(--e);
    border-bottom: solid 1px #f0f1f5;
    background-color: #fff;

    &--user {
      border-bottom-color: transparent;
      background-color: transparent;
    }
  }

  &__page {
    width: 100%;
    // padding-top: 4.5rem;
    // overflow-x: hidden;
    // overflow-y: initial;

    @include tablet {
      padding-top: 0;
    }

    &--basic {
      padding-top: 7.7rem;

      @include tablet {
        padding-top: 7rem;
      }
    }

    &--default {
      overflow: hidden;
    }

    &--grey {
      background-color: #f9f9f9;
    }

    &--faq {
      overflow: visible;
    }

    &--user {
      background-color: #f0f1f5;
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding: 0 2rem;
    }
  }

  &__modal {
    display: none;
  }

  &__cookies {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 6;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding-bottom: 2rem;

    @include tablet {
      padding-bottom: 0;
    }
  }

  &__offcanvas {
    pointer-events: all;
    position: fixed;
    top: 5.3rem;
    right: 0;
    bottom: 0;
    background-color: #fff; 
    z-index: 5;
    transition: transform 350ms var(--e);
    pointer-events: all;
    transform: translate3d(100%, 0, 0);
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #f0f1f5;

    &--mob-nav {
      transform: translate3d(-100%, 0, 0);

      @include tablet {
        display: none;
      }
    }

    @include tablet {
      width: 59.5rem;
      border-top: none;
    }
  }

  &__mob-nav {
    position: fixed;
    top: 5.3rem;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 4;
    transition: transform 350ms var(--e);
    pointer-events: all;
    width: 100vw;
    overflow: hidden;
    border-top: 1px solid #f0f1f5;

    @include tablet {
      display: none;
    }
  }

  &--user {
    background-color: #f0f1f5;
    padding-top: 0;
  }

  &.offcanvas-open {
    pointer-events: none;
    touch-action: none;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    @include tablet {
      width: 100%;
      pointer-events: all;
      touch-action: auto;
      overflow-x: hidden;
      overflow-y: auto;
      height: auto;
    }
  }

  &.register-open {
    .app {
      &__offcanvas {
        &--register {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.wizard-open {
    .app {
      &__offcanvas {
        &--wizard {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .navicon {
      &__bar {
        width: 1.5rem;
        background-color: var(--primaryColor);

        &--1 {
          transform: rotate(45deg);
        }

        &--2 {
          opacity: 0;
        }

        &--3 {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.login-open,
  &.register-open {
    .user-trigger {
      color: var(--primaryColor);

      &:before,
      &:after {
        opacity: 1;

        @include tablet {
          opacity: 0;
        }
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &__container {
        opacity: 0;
        transform: translate3d(0, 2rem, 0) scale(0.2);

        @include tablet {
          opacity: 1;
          transform: none;
        }
      }
    }

    .app {
      &__offcanvas {
        &--login {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.remind-open {
    .app {
      &__offcanvas {
        &--remind {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.resume-open {
    .app {
      &__offcanvas {
        &--resume {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.mob-nav-open {
    .app {
      &__offcanvas {
        &--mob-nav {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .navicon {
      &__bar {
        width: 1.5rem;
        background-color: var(--primaryColor);

        &--1 {
          transform: rotate(45deg);
        }

        &--2 {
          opacity: 0;
        }

        &--3 {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.header-up {
    .nav {
      transition: transform 550ms var(--easeOutBack), opacity 200ms linear 150ms;
      opacity: 0;
      transform: rotateX(91.8deg);

      @include tablet {
        transition: none;
        opacity: 1;
        transform: none;
      }
    }
  }
}
