.table {
  padding: 0.5rem 0 0.6rem;

  &__top {
    padding: 2.2rem 0;
  }

  &__row {
    border-top: 1px solid #f0f1f5;
    border-bottom: 1px solid #f0f1f5;
    font-weight: 500;
    margin: 1.9rem 0;
    position: relative;

    &--expandable {
      transition: background-color 220ms var(--e);
      cursor: pointer;
      padding-right: 3rem;
      @include tablet {
        &:hover {
          background-color: #f9f9f9;
        }
      }
    }

    &.is-open {
      .table {
        &__extra {
          display: block;
        }

        &__expander {
          &:after {
            transform: scale(1, 0);
          }
        }
      }
    }

    &.slideup-start {
      .table {
        &__expander {
          &:after {
            transform: scale(1, 1);
          }
        }
      }
    }

    &.slidedown-start {
      .table {
        &__expander {
          &:after {
            transform: scale(1, 0);
          }
        }
      }
    }
  }

  &__cols {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__col {
    padding: 1rem 1.9rem;
    flex: 1 0 auto;

    &--50 {
      width: 100%;

      @include tablet {
        width: 50%;
      }
    }

    &--80 {
      width: 100%;

      @include tablet {
        width: 80%;
      }
    }

    &--90 {
      width: 100%;

      @include tablet {
        width: 90%;
      }
    }

    &--12 {
      width: 50%;

      @include tablet {
        width: 12%;
      }
    }

    &--15 {
      width: 50%;

      @include tablet {
        width: 15%;
      }
    }

    &--10 {
      width: 50%;

      @include tablet {
        width: 10%;
      }
    }

    &--1 {
      width: 1%;

      @include tablet {
      }
    }
  }

  &__extra {
    flex: 1 0 100%;
    padding: 1.9rem;
    display: none;
  }

  &__label {
    // margin-top: -0.9rem;
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.2rem;
    font-weight: 400;
  }

  &__value {
  }

  &__download {
    font-weight: 700;
    padding: 0 0 0 3.7rem;
    color: var(--primaryColor);
    background-repeat: no-repeat;
    background-size: auto 2rem;
    background-image: url('../img/icon--doc.svg');
    transition: background-position 220ms var(--e);
    background-position: 0% 50%;

    // background-position: .2rem 50%;
    @include tablet {
      &:hover {
        color: var(--primaryActive);
        background-position: 0.2rem 50%;
        // background-position: 0rem 50%;
      }
    }
  }

  &__expander {
    position: absolute;
    top: 1.2rem;
    display: block;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    right: 0.5rem;

    @include tablet {
      top: 1.25rem;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #000;
      transition: transform 220ms var(--e);
    }

    &:before {
      width: 2rem;
      height: 1px;
      top: 0.9rem;
      right: 0;
    }

    &:after {
      width: 1px;
      height: 2rem;
      top: 0;
      right: 0.9rem;
    }
  }
}
