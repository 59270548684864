.filter {
  position: relative;
  background-color: #fff;

  &__input {
    display: block;
    width: 100%;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    border: 0.1rem solid #f0f1f5;
    padding: 1rem 6rem 0.9rem 5rem;
    position: relative;
    z-index: 1;
    font-weight: 500;

    &--blue {
      border-color: var(--primaryColor);

      &:focus {
      }
    }
  }

  &__placeholder {
    position: absolute;
    top: 1.1rem;
    font-weight: 500;
    left: 6rem;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 5.6rem;
    padding: 1.2rem 2rem;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__submit {
    padding: 1rem 1.6rem 0.7rem;
    right: 1px;
    background-color: #fff;
    top: 1px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    font-weight: 500;
  }
}
