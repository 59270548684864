.contacts {
  &__reachings {
    padding: 0.6rem 0 2rem;

    @include tablet {
      padding: 0.6rem 0;
      width: 42%;
    }
  }

  &__reaching {
    padding: 1.3rem 2rem 2.6rem 3.8rem;

    @include tablet {
      padding: 2.3rem 2rem 0.2rem 4rem;
    }
  }

  &__map {
    position: relative;
    height: 29rem;
    background-color: #ccc;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-right: -2rem;

    @include tablet {
      width: 58%;
      height: 40.3rem;
    }
  }
}
