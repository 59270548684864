.btn {
  transition: border-color 220ms var(--e), background-color 220ms var(--e), color 220ms var(--e);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  background-color: var(--primaryColor);
  border: solid 1px var(--primaryColor);
  position: relative;
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  padding: 1.3rem 1rem 1.2rem;

  &--disabled{
    pointer-events: none;
    opacity: 0.5;
  }

  @include tablet {
    padding: 2.1rem 1rem;
  }

  path {
    transition: fill 220ms var(--e);
    fill: currentColor;
  }

  @include tablet {
    width: 28rem;

    &:hover {
      background-color: var(--primaryActive);
      border-color: var(--primaryActive);

      .btn {
        &__icon {
          transform: scale(1.04);
        }
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      .btn {

        &__icon,
        &__text {}
      }
    }
  }

  &__text {}

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 1.4rem;
    width: 2rem;
    text-align: center;
    transition: transform 320ms var(--easeOutBack);

    @include tablet {
      height: 1.4rem;
      width: 1.8rem;
    }

    &--left {
      margin-right: 1rem;

      @include tablet {
        margin-right: 2rem;
      }
    }

    &--right {
      margin-left: 1rem;

      @include tablet {
        margin-left: 2rem;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
    }
  }

  &--narrow {
    @include tablet {
      width: 16.3rem;
    }
  }

  &--white-blue {
    color: var(--primaryColor);
    background-color: #fff;
    border-color: #fff;

    @include tablet {
      &:hover {
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
        color: #fff;
      }
    }
  }

  &--white {
    color: #000;
    background-color: #fff;
    border-color: #fff;

    @include tablet {
      &:hover {
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
        color: #fff;
      }
    }
  }

  &--ghost {
    color: var(--primaryColor);
    border-color: currentColor;
    background-color: transparent;

    @include tablet {
      &:hover {
        border-color: currentColor;
        background-color: transparent;
        color: #000;
      }
    }
  }

  &--white-ghost {
    color: #fff;
    border-color: currentColor;
    background-color: transparent;

    @include tablet {
      &:hover {
        // color: #000;
        // border-color: currentColor;
        // background-color: transparent;
        color: var(--primaryColor);
        background-color: #fff;
        border-color: #fff;
      }
    }
  }

  &--black {
    color: #000;
    border-color: currentColor;
    background-color: transparent;

    @include tablet {
      &:hover {
        // color: val(--primaryColor);
        color: #0035DD;
        border-color: currentColor; // border-color: val(--primaryColor);
        background-color: transparent;
      }
    }
  }

  &--full {
    @include tablet {
      width: 100%;
    }
  }

  &.is-disabled {
    color: #A3AEC2;
    background-color: #E2E2E2;
    pointer-events: none;
    border-color: #e2e2e2;
  }
}
