.form {
  position: relative;

  &__row-explain{
    font-size: 12px;
    line-height: 16px;
    color: #ff0000;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  input[type=number] {
      -moz-appearance:textfield;
  }

  &__row-input-wrap{
    position: relative;
  }

  &__phone-label{
    font-weight: 500;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    color: #909090;
    top: .1rem;
    padding: 1rem 1.9rem 1.1rem;
  }

  &__wizard-count{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__wizard-count-input{
    width: 7.8rem;
    height: 2.8rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    border: 1px solid #f0f1f5;
    background-color: #ffffff;
    color: #2b2b2b;
    font-family: var(--primaryFont);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.6rem;
    text-align: center;
  }

  &__wizard-count-label{
    font-family: var(--primaryFont);
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-top: .6rem;
    margin-left: .5rem;
    display: inline-block;
    vertical-align: top;
  }

  &--subscribe {
    margin-top: 1rem;

    @include tablet {
      margin-top: 3rem;
    }
  }

  &__content {
    // transform-style: preserve-3d;
    transition: 650ms transform var(--easeInBack), 200ms opacity var(--easeInBack);
    backface-visibility: hidden;
    width: 100%;

    &--proffer {
      background-color: #f0f1f5;
      padding: 1.8rem 2rem;

      @include tablet {
        padding: 4.5rem 10.6%;
      }
    }
  }

  &__footer {
    padding: 1.8rem 0;

    @include tablet {
      padding: 0;
    }
  }

  &__already {
    width: 100%;
    border-top: solid 1px #f0f1f5;
    padding: 1.9rem 0 0.1rem;
    margin-top: 0.4rem;

    @include tablet {
      padding: 3.2vh 0 0.1vh;
      margin-top: 4.2vh;
    }
  }

  &__done {
    transform-style: preserve-3d;
    transition: 650ms transform var(--easeInBack);
    backface-visibility: hidden;
    z-index: 1;
  }

  &__intro {
    padding: 0.6rem 0;
  }

  &__row {
    position: relative;
    padding: 0.6rem 0;

    &--with-error{
      padding-bottom: 1rem;
      @include tablet{
        padding-bottom: 1.5rem !important;
      }
    }

    &--with-error-second{
      padding-bottom: .7rem;
      @include tablet{
        padding-bottom: 0;
      }
    }

    &--amount{
      padding-bottom: 1.4rem;
      padding-top: 3rem;
    }

    @include tablet {
      display: flex;
      padding: 0.6rem 0;

      &--high {
        padding: 0.9rem 0;
      }

      &--amount{
        padding-bottom: 1.4rem;
        padding-top: 3rem;
      }
    }

    &--wizard-mid{
      color: #2b2b2b;
      font-size: 1.4rem;
      line-height: 1;
      display: flex;
      justify-content: space-between;
      font-family: var(--primaryFont);
      margin: 2rem 0;
      font-weight: 400;
    }

    &--hidden{
      display: none !important;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    display: block;
    padding: 0.7rem 0;

    &--hidden{
      opacity: 0 !important;
      width: 0 !important;
      height: 0 !important;
    }

    @include tablet {
      padding: 0;

      &--wizard{
        padding: 0.6rem 0;
      }

      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }

    &.has-error {
      .form {
        &__error {
          display: block;
        }
      }
    }
  }

  &__label {
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    font-weight: 400;
    display: block;
    padding: 0.8rem 0;
    position: relative;
    z-index: 1;
    &--wizard-v3{
      font-family: var(--primaryFont);
      font-size: 1.3rem;
      line-height: 1.8rem;
      padding: 0 0 1rem;
    }
    &--wizard-range{
      font-family: var(--primaryFont);
      font-size: 1.2rem;
      line-height: 1;
      padding: 0;
    }
    &--wizard-range-min{
      float: left;
    }
    &--wizard-range-max{
      float: right;
    }
  }

  &__input {
    font-weight: 500;
    display: block;
    width: 100%;
    position: relative;
    padding: 1rem 1.9rem 1.1rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    border: 0.1rem solid #f0f1f5;
    transition: border-color 220ms var(--e), box-shadow 220ms var(--e),
      background-color 1ms linear 999s;
    background-color: #fff;

    &--signature-phone{
      padding-left: 5.5rem;
      // line-height: 1;
      @include tablet{
        padding-left: 6rem;
      }
    }

    &.has-error {
      border-color: var(--red);
    }

    @include tablet {
      &:hover {
        border-color: #e2e2e2;
      }

      &:focus {
        border-color: #000;
      }
    }

    &--white {
      border-color: #fff;
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0);

      &:focus {
        box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
      }
    }
    &--faded {
      font-size: 1.4rem !important;
      
      @include tablet{
        margin-top: 1.2rem;
      }
      &.has-error{
        border-color: #f0f1f5;
      }
      &::-webkit-input-placeholder {
        color: #a3aec2;
      }
      &:-moz-placeholder {
        color: #a3aec2;
      }
      &::-moz-placeholder {
        color: #a3aec2;
      }
      &:-ms-input-placeholder {
        color: #a3aec2;
      }
      // color: #a3aec2;
    }
  }

  &__control {
    display: block;
    position: absolute;
    cursor: pointer;
    transition: border-color 220ms var(--e), box-shadow 220ms var(--e);
    width: 2.2rem;
    height: 2.2rem;
    left: -0.1rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    border: 0.1rem solid #f0f1f5;
    background-color: #fff;
    top: 1.5rem;

    @include tablet {
      top: 0.8rem;
    }

    &:hover {
      border-color: #e2e2e2;
    }

    &:focus {
      border-color: #000;
    }

    &--white {
      border-color: #fff;
      box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0);

      &:focus {
        box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
      }
    }

    &:before {
      transition: opacity 300ms var(--e), transform 300ms var(--easeOutBack);
      content: '';
      position: absolute;
      top: 0.4rem;
      left: 0.5rem;
      width: 1rem;
      height: 0.8rem;
      border-bottom: solid 2px #fff;
      border-left: solid 2px #fff;
      opacity: 0;
      transform: rotate(-135deg) scale(0.1);
    }
  }

  &__native {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: -9;
    overflow: hidden;
    display: block;

    &--file {
      cursor: pointer;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: all;
    }

    &.has-error {
      & + .form {
        &__control {
          border-color: var(--red);
        }

        &--white {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
        }
      }
    }

    &:focus {
      & + .form {
        &__control {
          border-color: #000;
        }

        &--white {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
        }
      }
    }

    &:checked {
      & + .form {
        &__control {
          background-color: var(--primaryColor);
          border-color: var(--primaryColor);

          &:before {
            transform: rotate(-45deg) scale(1);
            opacity: 1;
          }
        }
      }
    }

    &[disabled] {
      cursor: not-allowed;

      & + .form {
        &__control {
          cursor: not-allowed;

          & + .form {
            &__inlabel {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  &__inlabel {
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    line-height: 1.2857;
    padding: 1.1rem 1rem 0rem 4rem;

    @include tablet {
      padding: 1.1rem 1rem 0.3rem 4rem;
    }

    a {
      color: var(--primaryColor);
      text-decoration: underline;
    }
  }

  &__error {
    color: var(--red);
    font-size: 1.2rem;
    display: none;
    position: absolute;
    bottom: -1.2rem;

    &--show{
      display: block;
    }

    @include tablet {
      bottom: -1.8rem;
    }
  }

  &__range {
    // padding: 0.9rem 0 3.9rem;
    padding: 0 0 .4rem;
    position: relative;
    &--proffer{
      padding: 0.9rem 0 3.9rem;
    }

    &--amount {
      // padding: 0 0 0.9rem;
    }

    &--term {
      // padding: 0 2.8rem 0.9rem 0.8rem;
    }
  }

  &__submit {
    margin: 0.9rem 0;

    &--partners {
      margin: 2.8rem 0;
    }

    &--footer {
      @include tablet {
        margin-top: 4.2rem;
      }
    }
  }

  &__success {
    display: none;
  }

  &__help {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    right: 2rem;
    color: #a3aec2;
    font-family: var(--secondaryFont);
    font-size: 1.2rem;
    font-weight: 400;
    bottom: 1.9rem;
    @include tablet {
      bottom: 1.3rem;
    }
    &--top {
      bottom: auto;
      top: 1rem;
    }
  }

  &__trigger {
    text-decoration: underline;
    color: var(--primaryColor);
    font-style: inherit;
    display: inline-block;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;
  }

  &__term {
    width: 100%;
  }

  &.in-perspective {
    perspective: 800;
  }

  &.has-error {
    .form {

      .noUi-origin{
        transform: translateZ(0) !important;
      }

      &__content {
        transform: rotateY(180deg);

        &--no-flip {
          transform: translate3d(0, 0, 0);
        }
      }

      &__done--error {
        transform: rotateY(0deg);
      }
    }
  }

  &.has-success {

    .noUi-origin{
      transform: translateZ(0) !important;
    }

    .form {
      &__content {
        transform: rotateY(180deg);

        &--no-flip {
          // transform: none;
          transform: translate3d(0, 0, 0);
        }
      }

      &__done--success {
        transform: rotateY(0deg);
      }
    }
  }
}
