.basic {
  &__article {
    @include tablet {
      padding: 7rem 24% 7.8rem 0;
    }

    a {
      text-decoration: underline;
      color: var(--primaryColor);
    }

    ul {
      list-style: disc;
      padding-left: 2rem;
    }


    table {
      border-collapse: collapse;
      border-spacing: 0;
      margin: 3rem 0;
      width: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      display: block;

      @include tablet {
        overflow: visible;
      }

      tbody,
      thead {
        width: 40rem;

        @include tablet {
          width: 100%;
        }

      }

      thead {
        font-weight: 600;
      }

      td {
        padding: 1em;
        border-bottom: 1px solid #f0f1f5;
      }
    }
  }

  &__downloads {
    padding: 3rem 0 7.6rem;

    @include tablet {
      padding: 3rem 0 7.6rem;

    }
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0;

    @include tablet {
      padding: 3rem 0;

    }
  }

  &__reachout {
    background-color: #fff;
    padding: 10rem 0;

    @include tablet {
      padding: 10rem 0;
    }
  }

  &__cards {
    padding: 2rem 0 3.3rem;

    @include tablet {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 6rem 0 5.3rem;
      width: 100%;

    }
  }

  &__card {}
}
