.toast {
  -webkit-perspective: 800;
  perspective: 800;

  @include tablet {
    width: 26.8rem;
  }

  &__content {
    position: relative;
    background-color: #fff;
    padding: 0.4rem 3.2rem 0.4rem 8.9rem;
    min-height: 6.8rem;
    display: flex;
    transform: rotateX(109.8deg);
    transform-origin: 50% 100%;
    transition: 400ms transform var(--easeOutBack), 250ms opacity var(--e),
      250ms background-color var(--e), 250ms color var(--e);
    align-items: center;
    opacity: 0;
    &--no-media {
      padding: 0.4rem 3.2rem 0.4rem 2.1rem;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 6.8rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &__text {
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    line-height: 1.2857;
    font-weight: 400;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: 220ms transform var(--e);
    z-index: 2;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 2rem;
      left: 1.3rem;
      width: 1.5rem;
      height: 1px;
      // background-color: #000;
      background-color: currentColor;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include tablet {
      &:hover {
        transform: scale(1.1) rotate(180deg);
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &.is-active {
    .toast {
      &__content {
        opacity: 1;
        transform: rotateX(0deg);
      }
    }
  }
  @include tablet {
    &:hover {
      .toast {
        &__content {
          color: #fff;
          background-color: var(--primaryColor);
        }
      }
    }
  }
}
