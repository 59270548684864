@keyframes hover {
  0% {
    transform: translateY(0)
  }

  to {
    transform: translateY(-2%)
  }
}


.sequence {
  text-align: center;
  // animation: hover 3s infinite alternate cubic-bezier(.43, .05, .6, .99);
  animation: hover 2s infinite alternate var(--e);

  &__wrapper {

    // transition: none!important;
  }

  &__frame {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }
  }

  &__placeholder {
    width: 100%;
    text-align: center;

    height: 100%;
    object-fit: contain;

    @include tablet {
      height: auto;
      object-fit: initial
    }

    &.is-hidden {
      display: none;
    }
  }
}
