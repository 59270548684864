.term {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5rem;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);

    @include tablet {
      display: none;
    }
  }

  &__radios {
    position: relative;
    padding: 0 2rem 1rem 0;
    width: 100%;
    overflow: hidden;
    margin: 0 -0.8rem;

    @include tablet {
      margin: 0;
      height: 11rem;
      overflow: hidden;
    }
  }

  &__scroller {
    white-space: nowrap;
    display: flex;

    flex-direction: column;
    height: 12rem;
    flex-wrap: wrap;
    margin: -.8rem;

    @include tablet {
      // display: block;
      white-space: normal;
      flex-direction: row;
    }
  }

  // &__row {
  //   width: 100%;
  //   flex: 1 1 100%;
  //   padding: 0 0.8rem;

  //   @include tablet {
  //     padding: 0 0.8rem;
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //   }
  // }

  &__item {
    text-align: center;
    display: block;
    cursor: pointer;
    padding: 1rem 1.9rem 1.1rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    border: 0.1rem solid #f0f1f5;
    transition: border-color 220ms var(--e), box-shadow 220ms var(--e),
      background-color 1ms linear 999s;
    background-color: #fff;
    width: calc(50% - 1.6rem);
    margin: 0.8rem;

    @include tablet {
      // width: calc(50% - 1.6rem);
    }

    @include no-select();

    &.is-disabled {
      display: none;
    }

    &.has-error {
      border-color: var(--red);
    }

    &.is-focused {
      border-color: #e2e2e2;
    }

    &.has-value {
      border-color: #000;
    }

    &.is-featured {
      .term {
        &__label {
          &:before {
            display: inline-block;
          }
        }
      }
    }
  }

  &__custom {
    position: relative;
    display: block;
    width: 100%;
    z-index: 2;
    margin: 0.8rem 0 1.6rem;

    @include tablet {
      padding: 0 2.8rem 0 0.8rem;
      // padding: 0 2.8rem 0 0;
      margin: 0.8rem 0 1.6rem;
    }

    &.is-focused {
      .term {
        &__input {
          color: #000;
        }

        &__placeholder {
          color: #000;
        }
      }
    }
  }

  &__enter {
    color: #a3aec2;
    position: absolute;
    left: 3rem;
    font-size: 1rem;
    pointer-events: none;
    z-index: 2;
    top: 2.4rem;

    @include tablet {
      top: 1.8rem;
    }
  }

  &__input {
    display: block;
    font-weight: 500;
    width: 100%;
    padding: 1rem 1.9rem 1.1rem 66%;
    padding: 1rem 8.6rem 1.1rem;
    text-align: right;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    border: 0.1rem solid #f0f1f5;
    transition: border-color 220ms var(--e), box-shadow 220ms var(--e),
      background-color 1ms linear 999s;
    background-color: #fff;
    color: #a3aec2;
  }

  @include tablet {
    &:hover {
      border-color: #e2e2e2;
    }
  }

  &__label {
    pointer-events: none;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;

    &:before {
      content: '';
      display: block;
      position: relative;
      // flex: 1 1 1.3rem;
      left: -0.6rem;
      width: 1.3rem;
      height: 1.2rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      background-image: url('../img/icon--star--filled.svg');
      display: none;
    }
  }

  &__placeholder {
    position: absolute;
    color: #a3aec2;
    pointer-events: none;
    font-weight: 500;
    z-index: 2;
    top: 1.8rem;
    right: 4.8rem;

    .is-ios & {
      top: 2.1rem;
    }

    @include tablet {
      top: 1.1rem;
      right: 7rem;
    }
  }
}
