.about {
  &__section {
    position: relative;
    padding: 2.1rem 0;

    @include tablet {
      padding: 1.4rem;
    }
  }

  &__media {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    // height: 21.8rem;
    padding-top: 100%;

    @include tablet {
      height: auto;
      position: absolute;
      top: 3.7rem;
      width: 49.3%;
      padding-top: 49.3%;
      // height: 40rem;
      // bottom: 6.4rem;

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }
  }

  &__content {
    padding: 3.2rem 0;

    @include tablet {
      padding: 3.2rem 0;

      &--right {
        padding-left: 59.7%;
      }

      &--left {
        padding-right: 56%;
      }
    }
  }

  &__details {
    padding: 2.1rem 0 0rem;
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    font-weight: 400;

    @include tablet {
      padding: 3.2rem 0 0rem;
    }
  }

  &__meta {
    @include tablet {
      padding: 0.4rem 0;
    }
  }

  &__text {
    padding: 0rem 1rem 0 0;

    @include tablet {
      padding: 0rem 2rem 0 0;
    }

    p {
      // margin: 0em;
    }

    blockquote {
      margin: 2rem 0;
      font-weight: 700;
    }
  }

  &__actions {
    padding: 2.6rem 0;

    @include tablet {
      padding: 1.8rem 0;
    }
  }

  &__action {
  }
}
