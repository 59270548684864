.hero {
  position: relative;

  &__slider {
    position: relative;
    overflow: visible;

    @include tablet {
      min-height: 61rem;
    }
  }

  &__slide {
    height: auto;
    padding-bottom: 5rem;
    color: #fff;

    @include tablet {
      padding-bottom: 0;
      min-height: inherit;
    }

    // &--rose {
    //   background-color: #f37171;
    //   color: #fff;
    // }

    // &--pink {
    //   background-color: #ef7fa8;
    //   color: #fff;
    // }

    // &--blue {
    //   background-color: #0060dd;
    //   color: #fff;
    // }

    // &--yellow {
    //   background-color: #ffa110;
    //   color: #fff;
    // }

    // &--orange {
    //   background-color: #ff6d00;
    //   color: #fff;
    // }

    // &--cyan {
    //   background-color: #00a3e0;
    //   color: #fff;
    // }
    &--background {
      padding-bottom: 0;
    }
  }

  &__container {
    @include tablet {
      padding-right: 36rem;
      min-height: inherit;
    }
  }

  &__wrapper {
    position: relative;
    @include tablet {
      min-height: inherit;
    }
  }

  &__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 2;
    padding: 2.2rem;
    pointer-events: none;

    @media screen and (min-width: 769px) and (max-width: 1441px) {
      padding-right: 40rem;
    }

    @include tablet {
      padding: 3.8rem;
    }
  }

  &__bullet {
    border-radius: 50%;
    background-color: #fff;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    transition: background-color 220ms var(--e), transform 220ms var(--e);
    margin: 0.5rem;
    pointer-events: all;

    @include tablet {
      margin: 0.6rem;

      &:hover {
        transform: scale(1.2);
      }
    }

    &.is-active {
      transform: scale(1.5);
      background-color: var(--primaryColor);
    }
  }

  &__socials {
    position: absolute;
    top: 4.2rem;
    right: 39.4rem;
    z-index: 4;
  }

  &__nav {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    position: relative;
    pointer-events: all;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      border-left: solid 1px #fff;
      border-bottom: solid 1px #fff;
      top: 0.8rem;
      transition: border-color 300ms var(--e);
    }

    &--prev {
      &:before {
        left: 0.8rem;
        transform: rotate(45deg);
      }
    }

    &--next {
      &:before {
        right: 0.8rem;
        transform: rotate(-135deg);
      }
    }

    @include tablet {
      &:hover {
        &:before {
          border-color: #0035dd;
        }
      }
    }
  }
}
