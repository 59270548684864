:root {
  --primaryColor: #0035DD;
  --primaryActive: #0007C5;
  --primaryFont: 'Poppins';
  --secondaryColor: #FF5D00;
  --secondaryActive: darken(var(--secondaryColor), 10%);
  --secondaryFont: 'Cabin';
  --red: #FF0000;
  --pink: #EF7FA8;
  --azure: #00A3E0;
  --yellow: #FFA110;
  --greyLighter: #F9F9F9;
  --greyLight: #F0F1F5;
  --grey: #E2E2E2;
  --greyDark: #A3AEC2;
  --greyDarker: #414042;
}
