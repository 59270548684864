._only-desk {
  display: none;

  @include tablet {
    display: block;
  }
}

._only-mob {
  @include tablet {
    display: none;
  }
}
