.tabs {
  position: relative;

  &__media {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    height: 19.6rem;
    width: 67%;
    margin: 2.3rem auto 3.4rem;

    @include tablet {
      position: absolute;
      right: 16.6%;
      top: 0.3rem;
      width: 19.1%;
      height: 22.1rem;
    }
  }

  &__top {
    @include tablet {
      padding-right: 61%;
    }
  }

  &__triggers {
    border-bottom: 1px solid #000000;
    display: flex;
    position: relative;

    @include tablet {
      padding-top: 8.7rem;
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   background-color: var(--primaryColor);
    //   bottom: 0;
    //   height: 0.4rem;
    //   width: 50%;
    //   transition: transform 420ms var(--e);
    // }

    &--user {
      padding-top: 0rem;

      @include tablet {
        padding-top: 0rem;
      }
    }
    &--3 {
      &:before {
        width: 33%;
      }
    }
  }

  &__trigger {
    flex: 1 1 100%;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: color 220ms var(--e);
    padding: 1.6rem 0;
    white-space: nowrap;

    @include tablet {
      padding: 1.9rem 0;
    }

    &--user {
      padding: 0.8rem 0 1.8rem;

      @include tablet {
        padding: 0.8rem 0 1.8rem;
      }
    }

    &.is-active {
      color: var(--primaryColor);
    }

    @include tablet {
      &:hover {
        color: var(--primaryColor);
      }
    }
  }

  &__underline {
    display: block;
    position: absolute;
    background-color: var(--primaryColor);
    bottom: -1px;
    height: 0.4rem;
    width: 100%;
    transition: transform 420ms var(--e);
  }

  &__tag {
    margin-bottom: 0.2rem;
    margin-left: 0.2rem;
    margin-right: -2rem;
    display: inline-block;
  }

  &__contents {
    padding: 2.6rem 0 0;

    @include tablet {
      padding: 50px 0 0;
    }

    &--user {
      padding: 1.4rem 0;

      @include tablet {
        padding: 1.4rem 0;
      }
    }
  }

  &__content {
    display: none;

    &.is-active {
      display: block;

      @include tablet {
        display: flex;
      }
    }

    // iframe {
    //   border: 0;
    //   width: 100%;
    //   height: 33rem;
    //   @include tablet {
    //     padding-left: 5rem;
    //   }
    // }
  }

  &__embed {
    border: 0;
    width: 100%;
    &--signature {
      height: 33rem;
      @include tablet {
        padding-left: 5rem;
      }
    }
  }

  &__column {
    flex: 1 1 100%;

    @include tablet {
      padding-right: 5rem;
    }
  }

  &__block {
    padding: 1rem 0;
    position: relative;

    @include tablet {
      padding: 1.2rem 0;
    }
    &--has-icon {
      padding-left: 12rem;
      @include tablet {
        padding-left: 12rem;
      }
    }
  }

  &__icon {
    display: block;
    background-repeat: no-repeat;
    border-right-color: #000000;
    background-size: contain;
    background-position: 50% 50%;
    top: 4rem;
    left: 2rem;
    width: 8rem;
    height: 8rem;
    position: absolute;
  }

  &__label {
    font-weight: 500;
  }

  &__text {
    padding-top: 2rem;
  }

  &__form {
    width: 100%;
    display: block;
  }

  @for $i from 1 to 8 {
    &.active-tab--#{$i} {
      .tabs {
        &__underline {
          transform: translate3d((($i - 1) * 100%), 0, 0);
        }
      }
    }
  }
}
