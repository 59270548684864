.navicon {
  &__bar {
    width: 1.6rem;height: 2px;
    background-color: #000;
    position: absolute;
    left: 1.8rem;
    top: 2.9rem;
    display: block;
    transition: opacity 220ms var(--e), transform 220ms var(--e), width 220ms var(--e), height 220ms var(--e), background-color 220ms var(--e);
    &--1 {
        transform: translate3d(0, -0.6rem, 0);
    }

    &--2 {}

    &--3 {
        transform: translate3d(0, 0.6rem, 0);
    }
  }
}
