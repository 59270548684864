.footer {
  &__links {
    background-color: #f0f1f5;
    padding: 3rem 0;

    @include tablet {
      padding: 4.4rem 0 3.9rem;
    }
  }

  &__connect {
    background-color: #f0f1f5;
  }

  &__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      align-items: flex-start;
    }
  }

  &__logo {
    display: block;
    height: 2.8rem;
    width: 13.2rem;

    @include tablet {
      height: 3.4rem;
      width: 15.6rem;
      position: absolute;
      top: 1.1rem;
      left: 2rem;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__nav {
    width: 100%;
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    padding: 0 11.2rem 0 16rem;
  }

  &__nav-item {
  }

  &__nav-trigger {
    font-size: 1.4rem;
    font-weight: 500;
    display: block;
    padding: 1.8rem 2rem;
    color: #000000;
    background-size: 200% auto;
    background-position: 85% 50%;
    transition: background-position 350ms linear, color 220ms var(--e);

    &:hover {
      background-position: 15% 50%;
      color: var(--primaryColor);
    }
  }

  &__langs {
    @include tablet {
      position: absolute;
      right: 1.8rem;
      top: 0.6rem;
    }
  }

  &__reachings {
    padding: 1.9rem 0 1rem;
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      padding: 3.4rem 0 0;
      width: 52.2%;
    }
  }

  &__reaching {
    padding: 1.1rem 2rem 1.1rem 4rem;
    width: 100%;

    @include tablet {
      padding: 2.3rem 2rem 2.3rem 4rem;
      width: 50%;
    }
  }

  &__subscribe {
    padding: 2.2rem 2rem 0.8rem 2rem;
    margin: 0 -2rem;
    background-color: #f0f1f5;

    @include tablet {
      background-color: inherit;
      margin: 0;
      width: 47.8%;
      padding: 5.8rem 0 0 17.3%;
    }
  }

  &__bottom {
    position: relative;
    padding: 2.5rem 0;
    background-color: #fff;

    @include tablet {
      background-color: #f0f1f5;
      padding: 3.6rem 0;
    }
  }

  &__copyright {
    color: #a3aec2;
    font-weight: 600;
    width: 50%;

    @include tablet {
      width: auto;
      position: absolute;
      left: 2.3rem;
      top: 1.6rem;
    }
  }

  &__quick-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    padding: 0 0 0 0;
    justify-content: space-between;
    order: 3;

    @include tablet {
      justify-content: center;
      order: 0;
      padding: 0 12rem 0 8rem;
    }
  }

  &__quick-link {
    font-weight: 500;
    display: block;
    color: #000000;
    background-size: 200% auto;
    background-position: 85% 50%;
    transition: background-position 350ms linear, color 220ms var(--e);
    padding: 3.5rem 0rem;
    font-size: 1.2rem;

    @include tablet {
      padding: 1.8rem 2rem;
      font-size: 1.4rem;
    }

    &:hover {
      background-position: 15% 50%;
      color: var(--primaryColor);
    }
  }

  &__credits {
    display: block;
    order: 2;
    width: 7.6rem;
    height: 1.9rem;

    @include tablet {
      order: 0;
      width: auto;
      position: absolute;
      top: 2.4rem;
      right: 1.8rem;
      width: 7.2rem;
      height: 1.8rem;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

footer.second_footer {
  background: #413c3c;
  color: #fff;
  padding: 4rem 0;
  font-size: 1.4rem;

  .container {
    > * {
      margin: 2rem 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .links_holder {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: auto;
    gap: 2rem 2rem;

    @include small {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  a {
    position: relative;
    display: inline-block;
    padding: 0.8rem 0;

    &:before {
      content: "";
      position: absolute;
      transition: transform 0.3s ease;
      left: 0;
      bottom: 0.6rem;
      width: 100%;
      height: 1px;
      transform: translate3d(0, 0, 0) scaleX(0);
      background: #fff;
    }

    &:hover {
      text-decoration: none;
      font-weight: lighter;

      &:before {
        transform: translate3d(0, 0, 0) scaleX(1);
      }
    }
  }

  .footer_box {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 3rem 1rem 2rem;
    display: flex;
    justify-content: space-between;

    @include mobile {
      align-items: center;
      flex-direction: column;

      > * {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  .logo_holder {
    width: 10.7rem;
    padding-bottom: 0;
    margin-top: 1.2rem;

    &:before {
      display: none;
    }
  }
  .contacts a {
    padding-top: 0;
    margin-left: 2rem;

    @include mobile {
      text-align: center;
      margin-top: 1.2rem;
      padding-top: 0.8em;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .contacts {
    @include mobile {
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }
  .copy a {
    padding: 0;

    &:before {
      bottom: 0;
    }
  }
}
