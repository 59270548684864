h1,
h2,
h3,
h4,
h5 {
  font-family: var(--primaryFont);
  margin: 0;
  font-weight: 700;
  line-height: 1.3333;

  @include tablet {
    line-height: 1.3333;
  }
}

h1,
h2 {
  font-size: 2.4rem;
  font-weight: 500;
  // line-height: 1.25;
  line-height: 1.042;

  @include tablet {
    // line-height: 1.342;
    line-height: 1.042;
    font-size: 4.8rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.2;

  @include tablet {
    line-height: 1.3333;
    font-size: 2.4rem;
  }
}

h4 {
  font-size: 1.6rem;
  line-height: 1.2;

  @include tablet {
    // font-weight: 500;
    font-weight: 700;
    line-height: 1.3333;
    font-size: 2.4rem;
  }
}

p {
  margin: 1.5em 0;

  @include tablet {
    margin: 1.5em 0;
  }

  img {
    width: 100%;
  }
}
