.offers {
  overflow: hidden;
  &__slider {
    position: relative;
    overflow: visible;
  }

  &__slide {
    display: block;
    padding-top: 57%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    @include tablet {
      padding-top: 27%;
    }
  }

  &__banner {
    font-weight: 500;
    color: #fff;
    font-size: 1.6rem;
    padding: 2.3rem 50% 1rem 9.1%;
    height: 15.9rem;
    line-height: 1.125;
    margin-bottom: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include tablet {
      font-size: 2.4rem;
      margin-bottom: 0;
      background-position: 50% 50%;
      line-height: 1.29;
      height: 31.5rem;
      width: 49.3%;
      display: flex;
      justify-content: space-between;
      padding: 5.1rem 25% 1rem 5.4%;
    }
  }

  &__spacer {
    display: block;
    width: 100%;
    padding-top: 20%;
  }

  &__container {
    @include tablet {
    }
  }

  &__wrapper {
    position: relative;
  }

  &__actions {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 2;
    padding: 2.2rem;
    pointer-events: none;
    width: 100%;

    @include tablet {
      padding: 3.8rem;
    }
  }

  &__bullet {
    border-radius: 50%;
    background-color: #fff;
    background-color: var(--primaryColor);
    // opacity: .7;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    transition: background-color 220ms var(--e), transform 220ms var(--e);
    margin: 0.5rem;
    pointer-events: all;

    @include tablet {
      margin: 0.6rem;

      &:hover {
        transform: scale(1.2);
      }
    }

    &.is-active {
      transform: scale(1.5);
      background-color: var(--secondaryColor);
    }
  }

  &__socials {
    position: absolute;
    top: 4.2rem;
    right: 39.4rem;
    z-index: 4;
  }

  &__nav {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    position: relative;
    pointer-events: all;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      border-left: solid 1px var(--primaryColor);
      border-bottom: solid 1px var(--primaryColor);
      top: 0.8rem;
      transition: border-color 300ms var(--e);
    }

    &--prev {
      &:before {
        left: 0.8rem;
        transform: rotate(45deg);
      }
    }

    &--next {
      &:before {
        right: 0.8rem;
        transform: rotate(-135deg);
      }
    }

    @include tablet {
      &:hover {
        &:before {
          border-color: var(--secondaryColor);
        }
      }
    }
  }
}
