.media {
  position: relative;
  @include flex;

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    display: inline-block;
    -o-object-fit: contain;
    object-fit: contain;
    // vertical-align: middle;

    svg {
      vertical-align: middle;
      display: inline-block;
      width: 60%;
    }

    & > img {
      height: 100%;
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;

    }

    &--cover {
      background-size: cover;
      -o-object-fit: cover;
      object-fit: cover;

      & > img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;

      }
    }

    &--bottom {
      background-position: 50% 100%;
      object-position: 50% 100%;
      overflow: hidden;
    }

    &--frame {
      // opacity: 0;
      // transition: opacity 40ms var(--e);

      // &.is-active {
      //   opacity: 1;
      // }
    }
  }
}
