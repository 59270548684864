.user {
  &__container {
    max-width: 53.3rem;
    background-color: #fff;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
    // border-radius: 2rem;
    border: 0.1rem solid #f0f1f5;
    padding: 2rem;
    margin: 5.7rem auto;

    @include tablet {
      padding: 2rem 5rem;
      margin: 3.7rem auto;
    }
  }

  &__top {
    text-align: center;
    padding: 1rem 0;

    @include tablet {
      padding: 3rem 0;
    }
  }

  &__already {
    padding-top: 1.9rem;
    padding-bottom: 0.5rem;
    // padding: 1.9rem 0;
    // align-items: center;
    // align-content: center;
    justify-content: center;
    // @include flex();

    &--last {
      padding-top: 0;
      padding-bottom: 1.9rem;
    }
  }

  &__content {
  }

  &__tabs {
  }

  &__form {
    display: block;
    width: 100%;
  }

  .security_warning {
    font-family: var(--secondaryFont);
    font-weight: 400;
    font-size: 1.4rem;
    color: #ff5d00;
    margin-top: 1rem;
  }
}
