.trigger {
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.13em;
  padding: 0.5rem;
  text-transform: uppercase;
  color: var(--primaryColor);
  cursor: pointer;

  &:before {
    content: '';
    transform-origin: 50% 100%;
    transform: translate3d(0, 0.5rem, 0) scale(1, 0);
    transition: transform 320ms var(--e), opacity 320ms var(--e);
    height: .8rem;
    position: absolute;
    bottom: 0.8rem;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: -1;
    background-color: rgba(var(--primaryColor), .3);
  }

  &:after {
    content: '';
    transition: transform 320ms var(--e);
    position: absolute;
    right: 0.9rem;
    top: 1.2rem;
    border-style: solid;
    border-width: .3rem 0 .3rem .6rem;
    border-color: transparent transparent transparent var(--primaryColor);
    opacity: 0;
  }

  &--white {
    color: #fff;

    &:before {
      background-color: var(--primaryColor);
    }

    &:after {
      border-left-color: #fff;
    }
  }

  &--arrow {
    padding-right: 2.1rem;

    &:after {
      opacity: 1;
    }
  }

  .h &:hover {
    &:before {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1, 1);
    }

    &:after {
      transform: translate3d(0.3rem, 0, 0);
    }
  }
}
