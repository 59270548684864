.default {
  &__section {
    &--rose {
      background-color: #f37171;
      color: #fff;
    }

    &--pink {
      background-color: #ef7fa8;
      color: #fff;
    }

    &--blue {
      background-color: #0060dd;
      color: #fff;
    }

    &--dark-blue {
      background-color: #0035dd;
      color: #fff;
    }

    &--yellow {
      background-color: #ffa110;
      color: #fff;
    }

    &--orange {
      background-color: #ff6d00;
      color: #fff;
    }

    &--cyan {
      background-color: #00a3e0;
      color: #fff;
    }

    &--offers {
      // padding: 5.1rem 0 2rem;
      padding: 5.1rem 0 0;
    }

    &--tips {
      padding: 1.9rem 0;

      @include tablet {
        padding: 50px 0;
      }
    }

    &--recommend {
      padding: 5.3rem 0 1.4rem;

      @include tablet {
        padding: 10.1rem 0 1.4rem;
      }
    }

    &--team {
      padding: 2rem 0;
      @include tablet {
        padding: 4rem 0;
      }
    }

    &--partners {
      padding: 5.2rem 0 1rem;

      @include tablet {
        padding: 9.7rem 0 1rem;
      }
    }

    &--benefits {
      padding: 5.2rem 0 0;

      @include tablet {
        padding: 50px 0 1rem;
      }
    }

    &--methods {
      padding: 4rem 0 2rem;

      @include tablet {
        padding: 5rem 0;
      }
    }

    &--feedback {
      padding: 5.5rem 0;
      background-color: #f9f9f9;
    }

    &--proffer {
      // padding: 9.4rem 0 11rem;
      padding: 50px 0 1rem;

      @include tablet {
        // padding: 10rem 0 11rem;
        padding: 50px 0 1rem;
      }
    }

    &--conditions {
      padding: 9.4rem 0 3rem;

      @include tablet {
        padding: 50px 0;
      }
    }

    &--about {
      @include tablet {
        padding: 20px 0 0;
      }
    }

    &--grey {
      background-color: #f9f9f9;
    }

    &--plain {
      padding: 4.2rem 0;

      @include tablet {
        padding: 40px 0;
      }
    }

    &--plain-home {
      padding: 4.2rem 0;

      @include tablet {
        padding: 40px 0 0;
      }
    }

    &--positions {
      padding: 5.1rem 0 1.3rem;
      background-color: #f9f9f9;
    }

    &--reminder {
      padding: 5.1rem 0 1.9rem;

      @include tablet {
        padding: 4.5rem 0;
      }
    }

    &--services {
      background-color: #f0f1f5;
      padding: 8.1rem 0 1.3rem;

      @include tablet {
        padding: 40px 0 1.3rem;
      }
    }

    &--contacts {
      padding: 50px 0 3rem;
    }

    &--tabs {
      padding: 50px 0;
    }

    &--mobile-wizard {
      border-bottom: solid 1px #f0f1f5;

      @include tablet {
        display: none;
      }
    }
  }

  &__socials {
    position: absolute;
    top: 3.8rem;
    right: 1.2rem;
    display: none;

    @include tablet {
      display: block;
    }

    &--with-wizard {
      right: 38rem;
    }
  }

  &__header {
    position: relative;
    padding: 3.3rem 0;

    @include tablet {
      padding-top: 7rem;
      width: 58%;
    }

    &--high {
      @include tablet {
        min-height: 61rem;
      }
    }
    &--background {
      padding: 3.3rem 0 0;
      @include tablet {
        min-height: 61rem;
      }
    }

    &--narrow {
      @include tablet {
        width: 45%;
      }
    }

    &--list-right {
      @include tablet {
        width: 100%;
        padding-left: 59.2%;
        padding-top: 10rem;
      }
    }

    &--promo {
      padding-top: 6.7rem;
      padding-bottom: 3.8rem;

      @include tablet {
        padding-top: 11rem;
        padding-bottom: 8.8rem;
      }
    }

    &--business {
      padding-top: 6.7rem;
      padding-bottom: 3.8rem;

      @include tablet {
        padding-top: 9rem;
        padding-bottom: 8.7rem;
      }
    }

    &--with-wizard {
      @include tablet {
        width: 67%;
      }
    }
  }

  &__intro {
    padding: 0.5rem 0 0.5rem 0;

    @include tablet {
      padding: 1.3rem 15% 7.7rem 0;
    }

    &--push-down {
      @include tablet {
        padding-top: 6.3rem;
      }
    }

    &--narrow {
      @include tablet {
        padding: 0.6rem 29% 0.6rem 0;
      }
    }

    &--hero {
      @include tablet {
        padding: 0.5rem 14% 0.5rem 0;
      }
    }

    &--list-left {
      @include tablet {
        padding: 0.3rem 24% 0.7rem 0;
      }
    }

    &--list-right {
      @include tablet {
        padding: 1.3rem 0 0 0;
      }
    }

    &--promo {
      padding: 0.5rem 0 1rem 0;

      @include tablet {
        padding: 1.3rem 33% 7.2rem 0;
      }
    }

    &--business {
      @include tablet {
        padding: 2.3rem 20% 0rem 0;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  &__text {
    padding: 0.1rem 0;

    @include tablet {
      padding: 3.8rem 24% 2.3rem 0;
    }

    p {
      margin-bottom: 0;
    }

    ul {
      padding-top: 1.9rem;

      padding-left: 2rem;
      @include tablet {
        padding-top: 2.4rem;
      }
    }

    li {
      position: relative;
      display: block;
      padding-bottom: 2rem;

      &:before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #fff;
        left: -2.1rem;
        top: 0.7rem;
      }

      &:after {
        content: "";
        position: absolute;
        height: 0.3rem;
        width: 0.8rem;
        left: -1.8rem;
        top: 0.8rem;
        border-left: solid 1px #000;
        border-bottom: solid 1px #000;
        transform: rotate(-45deg);
      }
    }
    &--low {
      @include tablet {
        padding: 0 24% 0 0;
      }
    }
  }

  &__media {
    height: 20.8rem;

    @include tablet {
      height: 40rem;
      position: absolute;
      top: 9rem;
      left: 103.8%;
      width: 81.74%;
    }

    &--position {
      margin-top: 3rem;
    }

    &--high {
      @include tablet {
        top: 8.3rem;
        left: 78.2%;
        width: 96.3%;
      }
    }

    &--hero {
      @include tablet {
        top: 2rem;
        left: 76%;
        width: 120%;
      }
    }

    &--background {
      position: relative;
      bottom: -5.3rem;
      height: 24rem;
      @include tablet {
        height: 48rem;
        top: auto;
        margin-top: 0;
        bottom: 0;
        left: 76%;
        width: 120%;
      }
    }

    &--list-right {
      height: 25.7rem;

      @include tablet {
        height: 40rem;
        top: 10rem;
        left: 82%;
        width: 91%;
      }
    }

    &--list-left {
      height: 25.7rem;

      @include tablet {
        height: 40rem;
        top: 10rem;
        left: 3%;
        width: 49%;
      }
    }

    &--promo {
      margin: 3.3rem 0 0rem;

      @include tablet {
        margin: 0;
        top: 12.3rem;
        left: 95.5%;
        width: 87.8%;
      }
    }

    &--business {
      @include tablet {
        top: 8.1rem;
        left: 107.3%;
        width: 82.2%;
      }
    }

    &--slider {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }

  &__container {
    position: relative;

    &--with-wizard {
      @include tablet {
        padding-right: 36rem;
      }
    }
  }

  &__agreement {
    margin: 3.3rem 0 0.7rem;

    @include tablet {
      margin: 3.3rem 0 0.7rem;
      max-width: 50rem;
    }
  }

  &__actions {
    padding: 2.5rem 0 1.1rem;

    @include tablet {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--types {
      padding: 5.7rem 0;

      @include tablet {
        padding: 5.7rem 0;
      }
    }
    &--pull-down {
      @include tablet {
        margin-top: 16rem;
      }
    }
  }

  &__action {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.3rem;
    padding-bottom: 1.2rem;

    @include tablet {
      margin-bottom: 0;
      margin-right: 2rem;
      white-space: nowrap;
    }
    svg {
      path {
        fill: currentColor;
      }
    }

    &--nav-phone {
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: #0035dd !important;
      font-weight: 600;
      text-align: center;
      display: block;
      padding: 0;
      margin: 2rem 0 0 0;
      svg {
        margin-right: 2rem;
        margin-bottom: -0.2rem;
        path {
          fill: #0035dd;
        }
      }
    }
  }

  &__columns {
    padding-bottom: 0.3rem;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 7.3rem;
    }

    &--low {
      @include tablet {
        padding-bottom: 0;
      }
    }
  }

  &__block {
    padding-bottom: 1.2rem;

    @include tablet {
      padding-bottom: 3.4rem;
    }
  }

  &__column {
    @include tablet {
      width: 50%;
      flex: 0 0 50%;
    }

    &--push-left {
      @include tablet {
        padding-left: 9%;
      }
    }
  }

  &__listing {
    @include tablet {
      padding-right: 17%;
    }

    &--head {
      padding-right: 0;
      font-family: var(--secondaryFont);
    }

    &--small-pd {
      padding-top: 3rem;
      padding-bottom: 6rem;
      @include tablet {
        padding-right: 0;
        padding-top: 4rem;
        padding-bottom: 0;
      }
    }
  }

  &__form {
    background-color: #f0f1f5;
    margin: 2.8rem 0 2.7rem 0.7rem;
    color: #000;
    padding: 1.5rem 2rem;

    @include tablet {
      margin: 2.8rem 0 2.7rem 0.7rem;
      padding: 4.5rem 12.1% 1.4rem;
    }
  }

  &__reaching {
    &--single {
      padding: 1.3rem 0 0 4rem;
      margin-top: 5.3rem;

      @include tablet {
        padding: 2rem 0 0 4rem;
        margin-top: 5.3rem;
      }
    }
  }

  &__faq {
    padding: 10.5rem 0 1rem;
    position: relative;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 16rem 0 1rem;
    }

    &--loan {
      padding: 2.3rem 0;
      display: block;

      @include tablet {
        padding: 40px 0;
      }
    }
  }

  &__support {
    padding: 6rem 0 4rem;

    @include tablet {
      padding: 40px 0 4rem;
    }
  }

  &__reachout {
    padding: 2.7rem 0;

    @include tablet {
      padding: 2.7rem 0;
    }
  }

  &__tag {
    background-color: #000;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    font-size: 1.4rem;
    font-family: var(--secondaryFont);
    margin: 1.9rem 0 0.7rem;
    padding: 0.3rem 1.9rem 0.1rem;

    @include tablet {
      margin: 0.5rem 0 0;
      padding: 0.3rem 1.2rem 0.1rem;
    }
  }

  &__offers {
    padding: 2.2rem 0;

    @include tablet {
      padding: 1.8rem 0;
    }
  }

  &__tip {
    padding: 3.5rem 0;

    @include tablet {
      padding: 20px 0;
    }

    &--left {
      @include tablet {
        padding-right: 56%;
      }
    }

    &--right {
      @include tablet {
        padding-left: 59%;
      }
    }
  }

  &__recommends {
    padding: 2.1rem 0;

    @include tablet {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding: 2.1rem 0;
    }
  }

  &__recommend {
    margin-bottom: 1.6rem;

    @include tablet {
      width: 49.3%;
      margin-bottom: 1.6rem;
    }
  }

  &__partners {
    padding: 2.3rem 0;
    margin: 0 -2rem;

    @include tablet {
      margin: 0;
      padding: 2.1rem 0;
    }
  }

  &__contacts {
    padding: 3rem 0;

    @include tablet {
      padding: 3rem 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__cards {
    padding: 2.1rem 0;

    @include tablet {
      padding: 2.1rem 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__card {
    margin-bottom: 1.6rem;

    @include tablet {
      margin-bottom: 1.6rem;
    }
  }

  &__methods {
    padding: 2.3rem 0 5.3rem;

    @include tablet {
      padding: 2.3rem 0 5.3rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &__method {
    padding: 2rem 2.4rem;
    background-color: #f0f1f5;
    margin-bottom: 2rem;

    @include tablet {
      margin: 0;
      padding: 4.7rem 5.5% 1rem;
      width: 49.4%;
      flex: 0 0 49.6%;
    }
  }

  &__about {
    padding: 2.9rem 0 1.8rem;

    @include tablet {
      padding: 1.3rem 0 0;
    }
  }

  &__more {
    position: absolute;
    top: 0.3rem;
    right: 0.4rem;
    font-weight: 500;
    padding-right: 3.4rem;
    display: none;
    transition: color 220ms var(--e);

    &:after {
      transition: transform 220ms var(--e);
      content: "";
      top: 0.9rem;
      right: 1rem;
      position: absolute;
      width: 0.5rem;
      height: 0.9rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../img/icon--arrow.svg");
    }

    @include tablet {
      display: block;

      &:hover {
        color: var(--primaryColor);

        &:after {
          transform: translate3d(0.2rem, 0, 0);
        }
      }
    }
  }

  &__proffer {
    @include tablet {
      display: flex;
    }
  }

  &__wizard {
    background-color: #fff;
    height: 64rem;
    position: relative;
    z-index: 2;
    overflow: hidden;

    @include tablet {
      overflow: visible;
      height: 61rem;
      position: absolute;
      top: 5.6rem;
      right: 0;
      width: 37.3rem;
    }

    &--iframe.wizard {
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__service-text {
    &--bigger {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
    &--smaller {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }

  &__article {
    @include tablet {
      padding: 7rem 24% 7.8rem 0;
    }

    &--service {
      @include tablet {
        padding: 1rem 37.3rem 7.8rem 0;
      }
      img {
        margin: 3rem 0;
      }
    }

    a {
      text-decoration: underline;
      color: var(--primaryColor);
    }

    ul {
      list-style: disc;
      padding-left: 2rem;
    }
  }

  h1 {
    @include tablet {
      line-height: 1.1;
    }
  }
}
