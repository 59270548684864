.feedback {
  &__intro {
    padding: 0rem 0% 0rem 0;
    @include tablet {
    padding: 3rem 25% 1rem 0;

    }
  }

  &__reviews {
    position: relative;
    @include tablet {
    display: flex;
    flex-wrap: wrap;
    margin: -.8rem;

    }
  }

  &__review {}

  &__item {
    transition: opacity 220ms var(--e), transform 550ms var(--easeOutBack);
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    padding: 0.9rem 0 0.9rem 0;
    @include tablet {
    padding: .8rem;
    width: 33.333%;

    }

    &.is-hidden {
      padding: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translate3d(0, 1rem, 0) scale(.8);

    }
  }

  &__more {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-end;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 3.1rem 2rem 0rem;
    @include tablet {
    padding: 7.5rem 2rem 0rem;

    }

    &.is-hidden {
      visibility: hidden;
    }
  }

  &__more-label {
    cursor: pointer;
    padding-left: 0.9rem;
  }

  &__more-dots {
    cursor: pointer;
    padding-left: 1.8rem;
  }

  &__dot {

    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: var(--primaryColor);
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;

    &--1 {
      animation-delay: 200ms;
    }

    &--2 {
      animation-delay: 300ms;
    }

    &--3 {
      animation-delay: 400ms;
    }
  }
}
