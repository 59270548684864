.tip {
  position: relative;

  &__text {
    padding: 1.5rem 0;

    @include tablet {
      padding: 3.8rem 20% 2.3rem 0;
    }
  }

  &__media {
    width: 100%;
    height: 22rem;
    margin: 2.8rem auto 1.2rem;

    @include tablet {
      height: 36rem;
      position: absolute;
      width: 35%;
      top: -2.2rem;
    }

    &.sticky-img {
      @include tablet {
        top: 0;
        margin-top: 0;
      }
    }

    &--right {
      @include tablet {
        left: 59.8%;
      }
    }

    &--left {
      @include tablet {
        left: 0;
      }
    }
  }
}
