.user-trigger {
  color: var(--secondaryColor);
  transition: color 220ms var(--e), opacity 220ms var(--e);

  &:before,
  &:after {
    transition: opacity 220ms var(--e), transform 220ms var(--e);
    content: '';
    position: absolute;
    top: 2.8rem;
    left: 1.3rem;
    width: 1.5rem;
    height: 1px;
    background-color: var(--primaryColor);
    opacity: 0;
  }

  &__container {
    transition: opacity 220ms var(--e), transform 220ms var(--e);

    @include tablet {
      padding: 0.5rem 4rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      border-left: solid 1px #f0f1f5;
    }
  }

  &__icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    color: currentColor;

    @include tablet {
      margin-right: 1rem;
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
      display: block;
    }

    path {
      fill: currentColor;
    }
  }

  &__label {
    font-size: 1.4rem;
    font-weight: 500;
    display: none;

    @include tablet {
      display: block;
    }
  }

  @include tablet {
    &:hover {
      color: var(--primaryColor);
    }
  }

  &__link {
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
