.wizard {

  &--sticky{
    top: 0;
    margin-right: -2rem;
    margin-left: -2rem;
    @include tablet(){
      right: 2rem;
    }
    @include desktop(){
      margin-right: 0;
      margin-left: 0;
    }
  }

  @include tablet(){
    &__wrap{
      position: fixed;
      top: 2rem;
      right: 0;
      left: 0;
      width: 100%;
      max-width: 120rem;
      margin: auto;
      z-index: 1;
      &--bottom{
        position: absolute;
        top: calc(100% - 61rem - 5rem - 7.8rem);
      }
    }
  }

  &__wrapperm, &__form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__step {
    transform: translate3d(100%, 0, 0);
    transition: 650ms transform cubic-bezier(0.77, 1.43, 0.32, 0.96);
    background-color: #fff;
    // padding: 2rem 2rem 8rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: space-between;

    @include tablet {
      padding: 1.5rem 2rem;
      overflow: hidden;
    }

    &--1 {
      z-index: 1;
      transform: translate3d(0, 0, 0);
    }

    &--2 {
      z-index: 2;
    }

    &.is-active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__content {
    width: 100%;
  }

  &__footer {
    width: 100%;
  }

  &__notify {
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.25;
    padding: 1.1rem 1rem 2rem 3.1rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #ff6d00;
      display: block;
      left: 0rem;
      top: 1.6rem;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 2.1rem;
      left: 0.9rem;
      width: 0.4rem;
      height: 0.7rem;
      border-left: solid 1px #fff;
      border-bottom: solid 1px #fff;
      z-index: 2;
    }
  }

  &__btn {
    @include tablet {
      width: 100%;
    }
  }

  &__footnote {
    font-family: var(--primaryFont);
    font-size: 1.4rem;
    // font-size: 1.2rem;
    line-height: 1.29;
    // margin-top: 4rem;
    margin-top: 2rem;

    p {
      margin: 0.2em 0;
    }
  }

  &__socials {
    right: 39.5rem;
    position: absolute;
    top: 3.7rem;
    z-index: 2;
    white-space: nowrap;
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__toast {
    bottom: 0;
    z-index: 5;
    left: 0;
    right: 0;
    position: fixed;

    &--inner{
      display: none;
      @include tablet {
        display: block;
      }
    }

    @include tablet {
      position: absolute;
      left: auto;
      bottom: 1.9rem;
      right: 39.4rem;
    }
  }
}
