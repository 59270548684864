.faq {
  &__aside {
    @include tablet {
      width: 42.2%;
      position: sticky;
      top: 10rem;
    }
  }

  &__categories {
    font-weight: 500;
    padding: 0;

    @include tablet {
      padding: 3.8rem 0;
      width: 61.6%;
    }
  }

  &__category {
    cursor: pointer;
    padding: 0.3rem 0.7rem;
    margin: 2.4rem 0;

    @include tablet {
      margin: 2rem 0;
      padding: 0.3rem 0.7rem;
    }

    &.is-active {
      background-color: var(--secondaryColor);
      color: #fff;
    }
  }

  &__content {
    // padding: 0 1.2rem;

    @include tablet {
      padding: 0;
      width: 57.8%;
    }
  }

  &__filter {
    position: absolute;
    top: 1rem;
    width: 100%;

    @include tablet {
      top: auto;
      position: relative;
    }
  }

  &__tab {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__no-results {
    display: none;

    &.is-visible {
      padding-top: 4rem;
      display: block;
      @include tablet {
        padding-top: 0;
      }
    }
  }

  &__list {
    padding: 0.2rem 0;
    width: 100%;

    @include tablet {
      padding: 3rem 0;
    }
  }

  &__item {
    border-top: 1px solid #f0f1f5;
    border-bottom: 1px solid #f0f1f5;
    background-color: #fff;
    margin: 1.9rem 0;
    cursor: pointer;
    transition: background-color 220ms var(--e);
    padding: 0 2rem;

    @include tablet {
      &:hover {
        background-color: #f9f9f9;
      }
    }

    &.is-hidden {
      display: none;
    }

    &.is-open {
      .faq {
        &__answer {
          display: block;
        }

        &__icon {
          &:before {
            background-color: var(--primaryColor);
          }

          &:after {
            transform: scale(1, 0);
          }
        }
      }
    }

    &.slideup-start {
      background-color: #fff;

      .faq {
        &__icon {
          &:after {
            transform: scale(1, 1);
          }
        }
      }
    }

    &.slidedown-start {
      .faq {
        &__icon {
          &:after {
            transform: scale(1, 0);
          }
        }
      }
    }

    @include tablet {
      &--grey {
        &:hover {
          background-color: #f9f9f9;
        }
      }

      &:hover {
        .faq {
          &__icon {
            &:before,
            &:after {
              background-color: var(--primaryColor);
            }
          }
        }
      }
    }
  }

  &__question {
    cursor: pointer;
    font-weight: 500;
    position: relative;
    padding: 1.9rem 0;
  }

  &__label {
    padding-right: 2.2rem;
  }

  &__icon {
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    top: 2.2rem;
    right: -0.2rem;
    cursor: pointer;

    @include tablet {
      top: 2.2rem;
      right: -0.2rem;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #000;
    }

    &:before {
      width: 2rem;
      height: 1px;
      top: 0.9rem;
      right: 0;
    }

    &:after {
      width: 1px;
      height: 2rem;
      top: 0;
      right: 0.9rem;
      transition: transform 220ms var(--e);
    }
  }

  &__answer {
    display: none;
    padding: 0.2rem 10% 0.5rem 0.2rem;
    position: relative;

    p {
      margin-top: 0;
    }

    a:not(.btn) {
      color: var(--primaryColor);

      &[href^='tel'],
      &[href^='mailto'] {
        color: inherit;
      }
    }
  }

  &__cta {
    @include tablet {
      margin: 2rem 0;
    }
  }
}
