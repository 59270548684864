.review {
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.05);
  font-family: var(--secondaryFont);
  font-weight: 400;
  border-radius: 1.2rem 3rem 3rem;
  padding: 0.9rem 2.6rem 2.4rem;
  line-height: 1.29;
  font-size: 1.4rem;

  @include tablet {
    border-radius: 2.4rem 6rem 6rem;
    padding: 1.7rem 4.2rem 2.4rem;
    line-height: 1.29;
    font-size: 1.4rem;
  }

  &__top {
    padding: 0.2rem 4.6rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 4.8rem;

    @include tablet {
      min-height: 7.2rem;
      padding: 1.5rem 5.4rem;
    }
  }

  &__image {
    left: 0rem;
    top: 0.5rem;
    width: 3.2rem;
    height: 3.2rem;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 50%;

    @include tablet {
      left: 0rem;
      top: 1.4rem;
      width: 4.4rem;
      height: 4.4rem;
    }
  }

  &__name {
    color: #a3aec2;
    width: 100%;
  }

  &__stars {
    width: 100%;
    display: flex;
  }

  &__star {
    width: 1.7rem;
    height: 1.6rem;
    margin: 0.5rem 1rem 0.5rem 0;

    @include tablet {
      width: 1.7rem;
      height: 1.6rem;
      margin: 0.5rem 1rem 0.5rem 0;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    &--filled {
    }
  }

  &__text {
  }
}
