.reaching {
  position: relative;

  &__icon {
    position: absolute;
    top: 1.4rem;
    left: 0rem;
    width: 2rem;
    height: 2rem;

    @include tablet {
      top: 2.9rem;
      left: 0rem;
      width: 2rem;
      height: 2.3rem;

    }

    &--footer {
      top: 1.2rem;

      @include tablet {
        top: 2.2rem;

      }

    }

    svg {
      vertical-align: middle;
      display: inline-block;
      width: 100%;
      height: 100%;

    }

    path {
      fill: currentColor;
    }
  }

  &__label {
    font-weight: 700;
    font-size: 1.6rem;

    @include tablet {
      font-size: 2.4rem;

    }

    &--footer {
      font-size: 1.6rem;

      @include tablet {
        font-size: 1.6rem;

      }
    }
  }

  &__content {
    padding: 0.8rem 0 0;

    @include tablet {
      padding: 2.3rem 0 0;

    }

    &--footer {
      padding: 0.8rem 0;

      @include tablet {
        padding: 2.6rem 0;

      }

    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.1rem 0 0;
    white-space: normal;

    @include tablet {
      white-space: nowrap;
      padding: 0.2rem 0;

    }

  }

  &__col {
    &--s {
      width: 31%;

      @include tablet {
        width: 28%;

      }
    }

    &--l {
      width: 69%;

      @include tablet {
        width: 72%;

      }

    }
  }
}
