button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

svg:not(:root) {
  overflow: visible;
}

select {
  display: inline-block;
  width: 100%;
}

option {
  width: 100%;
  display: block;
}

textarea {
  resize: none;
}

main,
article,
section,
header {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  padding: 0;
  line-height: inherit;
  border-width: 0;
  border-style: solid;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeLegibility;

}
