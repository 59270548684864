.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  &__container {
  position: relative;
  flex-wrap: wrap;
  display: flex;

  padding: 3.3rem 1.5rem 3.8rem;
  @include tablet {
  padding: 6.9rem 4.6rem 8.9rem;

  }
  }

&__nav {
  display: block;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 3.6rem;
  @include tablet {
  top: calc(50% - 2rem);

  }

  &:before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-left: solid 1px  var(--primaryColor);
    border-bottom: solid 1px  var(--primaryColor);
    top: 0.1rem;
    transition: border-color 300ms var(--e);
  }

  &--prev {
    left: 0;

    &:before {
      left: 0.3rem;
      transform: rotate(45deg);
    }
  }

  &--next {
    right: 0;

    &:before {
      right: 0.3rem;
      transform: rotate(-135deg);
    }
  }

  @include tablet {
    &:hover {
      &:before {
        border-color: #0035DD;
      }
    }
  }
}
&__items {
  display: flex;
}
&__item {
  // display: flex;
  border: solid 1px transparent;
  text-align: center;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.6rem;
  transition: 220ms color var(--e), 220ms border-color var(--e);
  margin: 0 0.3rem;
  @include tablet {
  margin: 0 1.5rem;
    &:hover {
  color: var(--primaryColor);

    }
  }
  &--current {
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  }
}
}
