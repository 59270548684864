.socials {
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  color: #fff;

  &--dark {
    color: #000;
  }

  &__label {
    color: inherit;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    font-weight: 600;
    display: none;
    @include tablet {
    display: inline-block;

    }
  }

  &__item {
    color: inherit;
    display: inline-block;
    width: 2.2rem;
    height: 1.2rem;

    transition: transform 220ms var(--e);

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    path {
      fill: currentColor;
    }

    &--facebook {}

    &--linkedin {}

    &--instagram {}
    @include tablet {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
