.swiper {
  &-pagination {
    &-bullet {
      background-color: #fff;
      width: 1rem;
      height: 1rem;
      opacity: 1;
      transition: background-color 220ms var(--e), transform 220ms var(--e);

      @include tablet {
        &:hover {
          transform: scale(1.2);
        }
      }

      &.is-active,
      &-active {
        transform: scale(1.5);
        background-color: var(--primaryColor);
      }

    }
  }
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.5rem;
}
