.proffer {
  &__info {
    padding: 1rem 9.8% 1rem 0;

    @include tablet {
      width: 50.6%;
      padding: 1rem 9.8% 1rem 0;
    }
  }

  &__reviews {
    padding: 1rem 5.2rem;
    overflow: hidden;
    position: relative;
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__swiper {
    overflow: visible;
  }

  &__wrapper {
    align-items: center;
  }

  &__review {
    width: 100%;
  }

  &__text {
    display: block;
    padding: 0;

    @include tablet {
      display: none;
    }
  }

  &__actions {
    padding: 1rem 0 2.5rem;

    @include tablet {
      padding: 0;
    }
  }

  &__action {
    padding: 1rem 0 1rem 6rem;
    background-size: 0;
    position: relative;

    @include tablet {
      padding: 0.5rem 0;
      align-items: center;
      display: flex;
    }
  }

  &__nav {
    display: block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 2rem);
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      border-left: solid 1px #000;
      border-bottom: solid 1px #000;
      top: 1.3rem;
      transition: border-color 300ms var(--e);
    }

    &--prev {
      left: 0;

      &:before {
        left: 0.3rem;
        transform: rotate(45deg);
      }
    }

    &--next {
      right: 0;

      &:before {
        right: 0.3rem;
        transform: rotate(-135deg);
      }
    }

    @include tablet {
      &:hover {
        &:before {
          border-color: #0035DD;
        }
      }
    }
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-image: inherit;
    background-size: cover;
    background-position: 50% 50%;
    display: block;
    position: absolute;
    top: 0.8rem;
    left: -0.2rem;

    @include tablet {
      position: relative;
      top: auto;
      left: auto;
      width: 4rem;
      height: 4rem;
    }
  }

  &__label {
    font-weight: 500;

    @include tablet {
      margin: 0 1.5rem;
    }
  }

  &__amount {
    color: #a3aec2;
    font-family: var(--secondaryFont);
    font-weight: 400;
    font-size: 1.2rem;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__offer {
    position: relative;

    @include tablet {
      width: 50%;
    }
  }
}
