.langs {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2rem;
  border: 0.1rem solid #f0f1f5;
  background-color: #ffffff;
  padding: 0.4rem;
  position: relative;

  &__list {
    display: flex;
  }

  &__item {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .langs {
        &__trigger {
          background-color: #fff;
          border-color: var(--primaryColor);
        }
      }
    }

    &.is-active {
      .langs {
        &__trigger {
          color: #fff;
          border-color: var(--primaryColor);
          background-color: var(--primaryColor);
        }
      }

      &:hover {
        .langs {
          &__trigger {
            background-color: #fff;
            color: var(--primaryColor);
          }
        }
      }
    }
  }

  &__trigger {
    display: block;
    text-align: center;
    border-radius: 1.5rem;
    background-color: #0035dd;
    background-color: #fff;
    border: solid 1px #fff;
    padding: 0.3rem;
    height: 3rem;
    width: 4rem;
    color: #000000;
    font-size: 1.6rem;
    font-weight: 700;
    transition: background-color 220ms var(--e), border-color 220ms var(--e), color 220ms var(--e);
  }
}
