.consultant {
  position: relative;

  &__media {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  &__content {
    position: relative;
    z-index: 2;
    color: #fff;

    @include tablet {
      padding: 5.2rem 11%;

    }
  }

  &__contacts {
    padding: 2rem 0;

    @include tablet {
      padding: 2rem 0;

    }
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    padding: 0.1rem 0;

    @include tablet {
      padding: 0.2rem 0;

    }
  }

  &__label {
    font-weight: 300;
    width: 32%;

    @include tablet {
      width: 15%;

    }
  }

  &__value {
    width: 68%;
    font-weight: 700;

    @include tablet {
      width: 85%;

    }
  }

  &__cta {
    z-index: 3;

    @include tablet {
      position: absolute;
      bottom: 5rem;
      left: 10.8%;
    }
  }
}
