.already {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label {
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-weight: 400;
    font-size: 1.2rem;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__trigger {
    color: var(--primaryColor);
    font-weight: 500;
    font-size: 1.4rem;
    display: inline-block;
    cursor: pointer;
    margin: 0 .5rem;

    @include tablet {
      margin: 0 2.5rem;
      font-size: 1.6rem;
    }
  }
}
