.reachout {
  &__blocks {
    @include tablet {
      display: flex;
      justify-content: space-between;

    }
  }

  &__form {
    padding: 1.7rem 2.4rem 0.4rem;
    background-color: #f0f1f5;
    margin-bottom: 2.4rem;

    @include tablet {
      margin-bottom: 0;
      margin-right: 1.6rem;
      padding: 4.5rem 5.5% 3.4rem;
      width: 50%;

    }
  }

  &__consultant {
    padding: 2.2rem 2.6rem 2rem;
    height: 46.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      display: block;
      height: auto;
      padding: 0;
      width: 50%;

    }
  }
}
