.card {
  background-color: #fff;
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 1.6rem;
  border: solid 1px #f2f2f2;

  &--full {
    width: 100%;
    margin-left: 0;
  }

  @include tablet {
    margin-left: 1.6rem;

    &--half {
      width: calc(50% - 0.8rem);
      padding-top: 19%;

      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
    }

    &--third {
      width: calc(33.3333% - 1.1rem);
      padding-top: 19.2%;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    &--team {
      padding-top: 25%;
    }

    &--quater {
      width: calc(25% - 1.1rem);

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }
  }

  &--horizontal {
    @include tablet {
      padding-top: 0;
      padding-left: 25.5%;
    }
  }

  &__media {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 54%;

    @include tablet {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &--high {
      padding-top: 81%;
      @include tablet {
      }
    }

    &--half {
      @include tablet {
        padding-top: 39.6%;
      }
    }

    &--third {
      @include tablet {
        padding-top: 59.7%;
      }
    }

    &--horizontal {
      @include tablet {
        width: 51.7%;
        bottom: 0;
        padding-top: 0;
      }
    }

    &--team {
      padding-top: 78%;
      @include tablet {
        padding-top: 78%;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    padding: 2.1rem;
    position: relative;
    height: 100%;
    min-height: 18.6rem;
    min-height: 15rem;
    &--team {
      min-height: 0;
    }

    @include tablet {
      min-height: 21.7rem;
      padding: 3.3rem 4rem 2.9rem;

      &--half {
        min-height: 23.6rem;
      }

      &--horizontal {
        min-height: 28.1rem;
      }
      &--team {
        min-height: 0;
      }
    }
  }

  &__content {
    position: relative;
  }

  &__title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.2;

    @include tablet {
      font-size: 2.4rem;
      line-height: 1.29;
    }
  }

  &__subtitle {
    padding: 1.2rem 0;
    font-weight: 400;
  }

  &__text {
    padding: 2.3rem 0;

    @include tablet {
      padding: 2.1rem 0 2rem;
    }

    p {
      margin: 0;
    }
  }

  &__tag {
    color: #ff6d00;
    font-family: var(--secondaryFont);
    font-weight: 400;
    font-size: 1.4rem;
    padding: 2.1rem 0 0.5rem;

    @include tablet {
      font-size: 1.4rem;
      padding: 2.1rem 0 0.5rem;
    }
  }

  &__trigger {
    position: relative;
    font-weight: 500;
    padding-right: 3.4rem;
    transition: color 120ms var(--e);
    z-index: 2;

    &:after {
      transition: transform 220ms var(--e);
      content: '';
      top: 0.9rem;
      right: 1rem;
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      transform: rotate(-45deg);
      border-right: solid 1px currentColor;
      border-bottom: solid 1px currentColor;
    }
  }

  &__featured {
    position: absolute;
    color: #ffffff;
    font-family: Cabin;
    font-size: 1.4rem;
    font-weight: 700;
    background-color: #000;
    top: -3.5rem;
    left: 0rem;
    padding: 0.2rem 1.2rem;
    @include tablet {
      top: -4.5rem;

    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  @include tablet {
    &:hover {
      .card {
        &__trigger {
          color: var(--primaryColor);
          &:after {
            transform: rotate(-45deg) translate3d(0.1rem, 0.1rem, 0);
          }
        }
      }
    }
  }
}
