.modal {
  opacity: 0;
  display: none;
  transition: opacity 320ms var(--e), height 1ms linear 999s;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;

  @include tablet {
    padding: 0;
    flex-wrap: wrap;
    margin: auto;
  }

  &.is-entering {
    display: block;

    @include tablet {
      display: flex;
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    display: block;

    @include tablet {
      // display: flex;
    }
  }

  &.is-leaving {
    opacity: 0;
  }

  &__backdrop {
    z-index: 1;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
  }

  &__dialog {
    position: relative;
    overflow: hidden;
    margin: auto;
    z-index: 2;
    width: 100%; // height: 100%;

    // height: auto;
    // -webkit-overflow-scrolling: touch;
    @include tablet {
      overflow: visible;
      height: auto; // display: flex;
      display: block;
    }

    video {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      max-width: 100%;
      pointer-events: all;
    }
  }

  &__intro {
    @include tablet {
      padding: 13.9rem 0 7.7rem 0;
    }
  }

  &__close {
    cursor: pointer;
    position: fixed;
    background-color: #fff;
    top: 3rem;
    right: 3rem;
    width: 4.2rem;
    height: 4.2rem;
    z-index: 3;
    border: solid 1px var(--primaryColor);
    border-radius: 50%;
    pointer-events: all;
    transition: transform 350ms var(--e);

    @include tablet {
      top: 5rem;
      right: 5rem;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 2rem;
      left: 1.2rem;
      height: 1px;
      background-color: var(--primaryColor);
      width: 1.8rem;
      transition: transform 320ms var(--e);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    .h &:hover {
      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }
}