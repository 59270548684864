.range {

  &__grid{
    position: absolute;
    bottom: .4rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    margin: auto 0;
    &-item{
      width: 1px;
      height: 5px;
      background-color: #0035dd;
      &--hidden{
        background-color: transparent;
      }
    }
  }

  &__value {
    color: #a3aec2;
    font-family: var(--fontSecondary);
    font-size: 1.4rem;
    font-weight: 400;
    position: absolute;
    bottom: 1.3rem;

    &--min {
      left: 0;
    }

    &--max {
      right: 0;
    }
  }
}
