.tag {

  vertical-align: middle;
  color: #fff;
  background-color: var(--secondaryColor);
  padding: 0 0.3rem;
  position: relative;
  font-family: var(--secondaryFont);
  font-size: 1.2rem;
  font-weight: 400;

  &:before {
    content: '';
    position: absolute;
    top: 0.5rem;
    right: 100%;
    border: solid 0.4rem transparent;
    border-left-width: 0;
    border-right-color: var(--secondaryColor);
  }
}
