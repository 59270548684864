.choices {
  font-size: inherit;
  margin-bottom: 0;
  font-weight: 500;
  z-index: 3;

  &__inner {
    padding: 0.7rem 1.6rem 0.8rem;
    border: 0.1rem solid #fff;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0);
    background-color: #fff;
    font-size: inherit;
    border-radius: 0;
    @include tablet {
      transition: border-color 220ms var(--e), box-shadow 220ms var(--e),
        background-color 1ms linear 999s;
    }

    &:hover {
      border-color: #e2e2e2;
    }

    .is-focused &,
    .is-open & {
      border-color: #000;
    }
  }

  &__list {
    font-size: inherit;
    z-index: 3;
    z-index: 4;
    @include tablet {
      will-change: opacity, transform;
    }

    &--dropdown {
      display: none;
      @include tablet {
        display: block;
        transform: translate3d(0, -1rem, 0);
        opacity: 0;
        pointer-events: none;
        transition: opacity 100ms var(--e), transform 220ms var(--e);
      }

      &.is-active {
        display: block;
        @include tablet {
          transform: translate3d(0, 0, 0);
          opacity: 1;
          pointer-events: all;
        }
      }

      .choices {
        &__item {
          font-size: inherit;
        }

        &__list {
          max-height: 20rem;
        }
      }
    }
  }

  &.is-blue {
    .choices {
      &__inner {
        border-color: var(--primaryColor);
        box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.08);

        &:hover {
          // border-color: #e2e2e2;
          border-color: #000;
        }

        .is-focused &,
        .is-open & {
          border-color: #000;
        }
      }
    }
  }

  &[data-type*='select-one'] {
    &:after {
      width: 0.8rem;
      height: 0.8rem;
      border-width: 0;
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
      top: 2rem;
      right: 1.6rem;
      will-change: transform;
      z-index: 3;
      @include tablet {
        transition: transform 220ms var(--e);
      }
    }

    &.is-open {
      &:after {
        border-width: 0;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        transform: rotate(-135deg) translate3d(-0.5rem, -0.5rem, 0);
        @include tablet {
          transform: rotateX(180deg) rotate(45deg) translate3d(-0.5rem, -0.5rem, 0);
        }
      }
    }
  }
}
