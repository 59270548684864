.sitemap {
  padding: 4.3rem 0;
  width: 100%;

  &__top {
    padding: 1rem 0;
  }

  &__content {
    padding: 1.6rem 0;

    @include tablet {
      padding: 3.6rem 0;
    }
  }

  &__blocks {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__block {
    padding: 0 3rem 0 0;

    @include tablet {
      width: 33.33%;
    }
  }

  &__list {
  }

  &__item {
    display: none;
    &--featured {
      display: block;
    }
  }

  &__trigger {
    font-size: 1.4rem;
    display: inline-block;
    padding: 0.5rem 0;
    font-weight: 500;
  }
  &__expander {
    padding: 1rem 1rem 1rem 0;
    font-weight: 500;
    display: none;
    cursor: pointer;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: .6rem;
      height: .6rem;
      border-bottom: solid 1px #000;
      border-right: solid 1px #000;
      transform: rotate(45deg);
      top: 1.8rem;
      right: -0.2rem;
    }
    &.is-active {
      display: inline-block;
    }
    // &--more {
    //   display: none;
    // }
    &--less {
      &:after {
      top: 2.1rem;
        transform: rotate(-135deg);
      }
    }
  }
}
