.cookiebar {
  padding: 0 2rem;
  // transition: transform 320ms var(--e);
  background-color: #fff;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    padding: .1rem 4.2rem 0rem;
  }

  &__intro {
    font-weight: 600;
    padding: 0 0rem;

    @include tablet {
      width: 30%;
      padding: 0 1rem;

    }
  }

  &__text {
    font-size: 1.2rem;
    padding: 0;
    line-height: 1.25;

    @include tablet {
      width: 55.2%;
      padding: 0.7rem 2.1rem .7rem 0;

    }
  }

  &__actions {
    padding: 1.2rem 0;

    @include tablet {
      width: 14.8%;
      padding: 3.2rem 0;

    }
  }

  &__action {
    width: 100%;
  }

  &.is-active {
    border-bottom: solid 1px #f2f2f2;
    max-height: 120vh;

    @include tablet {
      max-height: 18rem;
    }

  }
}
