.method {
  &__content {}

  &__title {
    font-size: 2rem;
    font-weight: 500;
    @include tablet {
    font-size: 2.4rem;

    }
  }

  &__description {
    padding: 1.7rem 0 0.3rem;

    p {
      margin: 0;
    }
  }

  &__provider {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    display: block;
    height: 4.5rem;
    margin: 2.5rem 0 0.2rem;
    @include tablet {
    height: 9.1rem;
    margin: 8.4rem 0 6.6rem;

    }

  }

  &__form {}
}
