.listing {
  padding: 0 2.1rem;

  &__title {
    // padding: 3.9rem 0 .2rem;
    padding: .9rem 0 .2rem;
    @include tablet {
    padding: 2.6rem 0 .2rem;

    }
  }

  &__list {
    padding-top: 1.9rem;
    @include tablet {
    padding-top: 2.4rem;
      
    }

    &--head {
      padding-top: 0.1rem;
      font-size: 1.4rem;
    @include tablet {
      
    }
    }
    &--reminder {
      padding-top: 0;

    }

    &--small-pd{
      padding-top: 1.3rem;
      @include tablet {
        padding-top: 1.3rem;
      }
    }
  }

  &__item {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #fff;
      left: -2.1rem;
      top: 0.7rem;
    }

    &:after {
      content: '';
      position: absolute;
      height: .3rem;
      width: .8rem;
      left: -1.8rem;
      top: 0.8rem;
      border-left: solid 1px #000;
      border-bottom: solid 1px #000;
      transform: rotate(-45deg);

    }
    &--dark {
      &:before {
        background-color: #000;
      }
      &:after {
        border-color: #fff;
      }

    }
    &--high {
      margin: 2.5rem 0;
      font-weight: 300;
    }
  }
}
