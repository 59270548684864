.file {
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 12.1rem;
  color: var(--primaryColor);
  // border: solid .2rem var(--primaryColor);
  border: solid .2rem currentColor;
  transition: opacity 220ms var(--e);
  font-weight: 500;

  &__label {
    display: inline-block;
    max-width: 80%;
    vertical-align: middle;

    &--add {

      .has-value & {
        display: none;
      }
    }

    &--name {

      display: none;

      .has-value & {
        display: inline-block;
      }
    }
  }

  &__icon {
    vertical-align: middle;

    display: inline-block;
    margin-right: 2rem;

    svg {
      display: block;
      width: 1.4rem;
      height: 1.7rem;
    }

    path {
      fill: currentColor;
    }
  }
}
