.done {
  @include flex;
  background-color: var(--primaryColor);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);

  &__text {
    padding: 1.2rem;
    &--hide{
      display: none;
    }
  }

  &__close {
    display: block;
    width: 5.4rem;
    height: 5.4rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transition: 220ms transform var(--e);

    &:before,
    &:after {
      content: '';
      background-color: #fff;
      position: absolute;
      top: 2.7rem;
      left: 1.7rem;

      width: 2rem;
      height: 1px;

    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include tablet {
      &:hover {
        transform: scale(1.1) rotate(180deg);
      }
    }
  }
}
