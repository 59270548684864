html {
  background-color: #fff;
  color: #000;
  font-size: 10px;
}

body {
  font-family: var(--primaryFont);
  margin: 0;
  background-color: inherit;
  color: inherit;
  position: relative;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5;

  @include tablet {
    font-size: 1.6rem;
    line-height: 1.5625;
  }

  ::-webkit-selection {
    color: #fff;
    background-color: var(--azure);
  }

  :-moz-selection {
    color: #fff;
    background-color: var(--azure);
  }

  ::selection {
    color: #fff;
    background-color: var(--azure);
  }

  ::placeholder {
    color: #000;
    opacity: 1;
    transition: all 350ms var(--e);
  }
}

:focus::placeholder {
  opacity: 0.3;
  // letter-spacing: .14em;
  // font-size: .8em;
}

a {
  text-decoration: none;

  &[href^="tel"] {
    color: inherit;
  }
}

table {
  max-width: 100%;

  a {
    text-decoration: underline;
  }
}

tr {
  vertical-align: top;
}

td {
  text-align: inherit;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  list-style-type: none;
}

li {
  margin: 0;
}
