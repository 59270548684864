.nav {
  &__container {
    overflow: auto;
    @include tablet {
      overflow: visible;
    }
  }

  &__list {
    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
      padding: 0 18rem;
    }
  }

  &__item {
    position: relative;
    display: inline-block;

    @include tablet {
      display: block;
    }

    &.is-active {
      &:after {
        content: '';
        position: absolute;
        bottom: 1px;
        height: 2px;
        left: 2rem;
        right: 2rem;
        background-color: var(--primaryColor);
      }

      .nav {
        &__trigger {
          color: var(--primaryColor);
        }
      }
    }
    @include tablet {
      display: block;
      &:hover {
        .nav {
          &__submenu {
            transform: translate3d(0, 100%, 0);
            padding: 1.4rem 0 1.8rem;
          }
        }
      }
    }
  }

  &__trigger {
    font-size: 1.4rem;
    font-weight: 500;
    display: block;
    color: #000000;
    background-size: 200% auto;
    background-position: 85% 50%;
    transition: background-position 350ms linear;
    padding: 1rem 1.7rem 1.3rem;
    background-color: #fff;

    @include tablet {
      padding: 1.8rem 2rem;

      &:hover {
        background-position: 15% 50%;
        color: var(--primaryColor);
      }
    }
  }

  &__submenu {
    position: absolute;
    text-align: left;
    left: -0.5rem;
    z-index: -1;
    background-color: #fff;
    bottom: 0;
    padding: 0;
    transition: transform 220ms var(--e), padding 320ms var(--e);
    display: none;

    @include tablet {
      display: block;
    }

  }
  &__subitem {
    display: block;
  }
  &__subtrigger {

    display: block;
    white-space: nowrap;
    padding: 0.4rem 2.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    @include tablet {
      
      &:hover {
        color: var(--primaryColor);
      }
    }
  }
}
