.header {
  &__container {
    position: relative;
    text-align: center;
    perspective: 800;
  }

  &__logo {
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    &:hover {
    }

    &--mobile {
      display: inline-block;
      height: 5.96rem;
      width: 17rem;
      cursor: pointer;
      padding: 1rem;

      @include tablet {
        display: none;
      }
    }

    &--desktop {
      display: none;
      position: absolute;
      top: 1.1rem;
      left: 2rem;
      height: 3.4rem;
      width: 18rem;
      z-index: 3;

      @include tablet {
        display: block;
      }
    }
  }

  &__navicon {
    position: absolute;
    top: 0;
    left: 0;
    height: 5.4rem;
    width: 5.4rem;
    display: inline-block;

    @include tablet {
      display: none;
    }
  }

  &__user-trigger {
    cursor: pointer;
    position: absolute;
    z-index: 4;
    right: 0;
    top: 0;
    height: inherit;
    padding: 2rem 1.3rem;
    width: 5.3rem;

    @include tablet {
      padding: 1.2rem 0;
      width: 15.8rem;
    }
  }

  &__nav {
    position: absolute;
    top: 5.5rem;
    left: 0;
    right: 0;
    border-bottom: solid 1px #f0f1f5;
    border-top: solid 1px #f0f1f5;
    white-space: nowrap;
    background-color: #fff;
    overflow: hidden;
    transform-origin: 50% 0;
    transition: transform 550ms var(--easeOutBack), opacity 200ms linear;
    display: none;

    @include tablet {
      border: none;
      overflow: visible;
      background-color: transparent;
      white-space: normal;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      display: block;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 5rem;
      pointer-events: none;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );

      @include tablet {
        display: none;
      }
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    display: block;
    top: 0.4rem;
    right: 1.1rem;
    width: 5.6rem;
    height: 5.5rem;
    transition: 220ms transform var(--e);
    transform-origin: 50% 50%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 3rem;
      height: 1px;
      background-color: #414042;
      top: 2.7rem;
      left: 1.4rem;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include tablet {
      &:hover {
        transform: scale(1.1) rotate(180deg);
      }
    }
  }
}
