.assoc {
  padding: 0.9rem 0;
  position: relative;
  overflow: visible;

  &__table {
  }

  &__top {
    position: sticky;
    top: 5rem;
    background-color: #fff;
    padding-top: 4rem;
    z-index: 3;
  }
  &__filter {
    padding-bottom: 2rem;
    @include tablet {
      padding-bottom: 4.2rem;
    }
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #f0f1f5;
    border-top: 1px solid #f0f1f5;
    margin-bottom: 1.8rem;
    align-items: center;
    padding: 1.9rem 0 0rem;

    @include tablet {
      padding: 2.2rem 0;
    }

    &--head {
      border-top: none;
      padding: 1.1rem 0;
      margin-bottom: 0.9rem;
    }

    &--desktop {
      display: none;

      @include tablet {
        display: flex;
      }
    }

    &--mobile {
      @include tablet {
        display: none;
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__col {
    padding-right: 2rem;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.79;

    &--10 {
      width: 10%;
    }

    &--18 {
      width: 18%;
    }

    &--44 {
      width: 44%;
    }

    &--56 {
      width: 56%;
    }
  }

  &__label {
    color: #2b2b2b;
    font-family: var(--secondaryFont);
    font-size: 1.2rem;
    font-weight: 400;
  }

  &__value {
  }

  &__icon {
    display: block;
    width: 2.5rem;
    padding: 1.7rem 0; // height: 2rem;

    @include tablet {
      // text-align: center;
      width: 100%;
      margin: auto;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.8rem 0;
  }
  &__no-results {
    padding: 3rem;
    display: none;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    &.is-visible {
      display: block;
    }
  }
  &__pagination {
    &.is-hidden {
      display: none;
    }
  }
}
