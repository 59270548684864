.summary {
  // background-color: #f0f1f5;
  position: relative;
  // padding: 0.4rem 2.2rem 1.7rem;
  padding: 0.8rem 0;

  &__back {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    padding: 2.1rem 1.5rem 1.6rem 1.7rem;
    cursor: pointer;
    color: var(--primaryColor);
    font-weight: 500;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 2.9rem;
      right: -0.8rem;
      width: 1.5rem;
      height: 1px;
      background-color: #000;
      transition: 220ms transform var(--e);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include tablet {
      &:hover {
        &:before {
          transform: rotate(225deg);
        }

        &:after {
          transform: rotate(135deg);
        }
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.56;
    padding: 0.9rem 11rem .9rem 0;
    margin-bottom: 1.9rem;
  }

  &__table {
    padding: 2.3rem 0 0.5rem;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    font-family: var(--secondaryFont);
    font-size: 1.4rem;
    padding: 0.4rem 0 0.3rem;

    &--bold {
      font-weight: 700;
    }
  }

  &__col {
    &--label {
      width: 70%;
    }

    &--val {
      width: 30%;
      text-align: right;
    }
  }
}
