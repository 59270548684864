.offcanvas {
  overflow: hidden;

  &__actions{
    padding: 2rem;
    background-color: #F0F1F5;
    @include tablet{
      padding: 0;
      background-color: transparent;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3rem 1.8rem;
    -webkit-overflow-scrolling: touch;

    @include tablet {
      padding: 5.4vh 12%;
    }
  }

  &__top {
    padding: 0.6rem 0;

    @include tablet {
      padding: 1.9vh 0;
    }
  }

  &__container {
    height: 100%;
  }

  &__form {
    width: 100%;
  }

  &__close {
    position: absolute;
    top: 2.9rem;
    right: 1.7rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: 220ms transform var(--e);

    &--user {
      display: none;

      @include tablet {
        display: block;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 2rem;
      left: 1.2rem;
      width: 1.5rem;
      height: 1px;
      background-color: #000;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include tablet {
      &:hover {
        transform: scale(1.1) rotate(180deg);
      }
    }
  }

  &__actions {}

  &__menu {
    padding: 0 2.3rem;
  }

  &__list {
    padding: 3rem 0 3.1rem;
    border-bottom: 1px solid #f0f1f5;
  }

  &__item {
    &--s {}
  }

  &__trigger {
    display: inline-block;
    vertical-align: middle;
    padding: 0.95rem 0;
    font-size: 1.6rem;
    font-weight: 500;

    &--s {
      // padding: 0rem 0 0.9rem;
      font-size: 1.2rem;
    }
  }

  &__details {
    display: inline-block;
    margin-left: .9rem;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: var(--secondaryFont);
  }

  &__submenu {
    padding: 0.9rem 2.3rem;
  }

  &__footer {
    padding: 3rem 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__langs {}

  &__socials {}
}
