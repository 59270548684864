.noUi {

  &-horizontal {
    height: 2.1rem;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;

    &:before {
      content: '';
      position: absolute;
      top: 1rem;
      height: 1px;
      background-color: #0035dd;
      left: 0;
      right: 0;
    }

    .noUi {

      &-handle {
        width: 2rem;
        height: 2rem;
        background-color: #0035dd;
        border-radius: 50%;
        border: none;
        box-shadow: none;
        cursor: pointer;
        top: 0;

        &:before, &:after{
          width: .6rem;
          height: .9rem;
          background-color: transparent;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          pointer-events: none;
          transition: 0.3s ease opacity;
        }
        
        &:before{
          background-image: url('../img/mini-arrow-left.svg');
          left: -6px;
        }

        &:after{
          background-image: url('../img/mini-arrow-right.svg');
          right: -6px;
        }

        // &:before, &::after{
        //   width: .6rem;
        //   height: .6rem;
        //   border-style: solid;
        //   border-width: 1px;
        //   background: transparent;
        //   right: 0;
        //   left: 0;
        //   top: 0;
        //   bottom: 0;
        //   margin: auto;
        //   transform-origin: center;
        //   opacity: 0;
        //   transition: 0.2s ease opacity;
        // }

        // &:before{
        //   border-color: #fff transparent transparent #fff;
        //   transform: rotate(-45deg);
        //   left: -2px;
        // }
        // &:after{
        //   border-color: transparent #fff #fff transparent;
        //   transform: rotate(-45deg);
        //   right: -2px;
        // }

      }

      &-tooltip {
        bottom: auto;
        top: 120%;

        &:before {
          content: '';
          position: absolute;
          width: 0;
          top: -0.7rem;
          left: calc(50% - 0.7rem);
          height: 0;
          border-style: solid;
          border-width: 0 0.7rem 0.7rem 0.7rem;
          border-color: transparent transparent #fff transparent;

          .wizard & {
            display: none;
          }
        }
        &--wizard {
          display: none;
        }
      }
    }
  }

  &-tooltip {
    border: none;
    border-radius: 0;
    background: #fff;
    padding: 0rem 1rem;
    font-weight: 500;
  }
}

.noUi-handle--update-term{
  .noUi-handle-icon{
    &:after{
      display: block;
    }
  }
}

.form__range--proffer{

  .noUi{
    &-base{
      width: calc(100% - 4rem);
      margin: 0 0 0 2.3rem;
    }
    &-horizontal{
      height: 4rem;
      &:before{
        top: 2rem;
        background-color: #000;
      }
    }
    &-handle {
      height: 4rem;
      width: 4rem;
      top: 0;
      background-size: 0 0;
      border-radius: 50%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      cursor: pointer;

      &-icon {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        transition: transform 220ms var(--e), background-color 220ms var(--e);
        z-index: 3;
        border-radius: 50%;
        box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.08);
        background-image: url('../img/handle__icon.svg');

        &:after{
          content: attr(data-term-content);
          font-size: 1.3rem;
          font-style: normal;
          line-height: 1;
          position: absolute;
          bottom: -1.6rem;
          left: 0;
          right: 0;
          z-index: 1;
          pointer-events: none;
          text-align: center;
          display: none;
        }

        &--wizard {
          background-color: #000;
        }
      }

      @include tablet {
        &:hover {
          .noUi {
            &-handle {
              &-icon {
                transform: scale(1.1);

                // &--wizard {
                //   background-color: var(--primaryColor);
                // }
              }
            }
          }
        }
      }

      &--wizard {
        height: 2rem;
        width: 2rem;
      }

      &.noUi-active {
        .noUi {
          &-handle {
            &-icon {
              transform: scale(0.9);

              &--wizard {
                background-color: var(--primaryColor);
              }
            }
          }
        }

        @include tablet {
          &:hover {
            .noUi {
              &-handle {
                &-icon {
                  transform: scale(0.9);
                }
              }
            }
          }
        }
      }
    }
  }
}
