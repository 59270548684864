.notfound {
  &__container {
    min-height: 100vh;
    max-width: 100rem;
    @include flex();

    h1 {
      color: var(--primaryColor);
      line-height: 1.5;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 2.3rem;
    padding: 4rem 0;
  }

  &__footer {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: var(--secondaryFont);
    padding: 3.6rem 0;
  }

  &__link {
    display: inline-block;
    padding: 1.6rem 2.3rem;
    color: var(--primaryColor);
    text-decoration: underline;
  }
}
