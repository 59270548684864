@mixin mobile() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin small() {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin laptop() {
  @media only screen and (min-width: 1279px) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}
